import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Subject } from 'rxjs';
// import {ThemePalette} from '@angular/material/core';
// import {ProgressSpinnerMode} from '@angular/material/progress-spinner'
@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  // color: ThemePalette = 'primary';
  // mode: ProgressSpinnerMode = 'indeterminate';
  // value = 10;

  fechaIniCntr: string
  fechaFinCntr: string
  rfcProveCntr: string
  LoadingCntr: boolean = false;

  fechaIniImss: string
  fechaFinImss: string
  rfcProveImss: string
  LoadingImss: boolean = false;

  fechaIniInfo: string
  fechaFinInfo: string
  rfcProveInfo: string
  LoadingInfo: boolean = false;

  fechaIniNomi: string
  fechaFinNomi: string
  rfcProveNomi: string
  LoadingNomi: boolean = false;

  fechaIniStps: string
  fechaFinStps: string
  rfcProveStps: string
  LoadingStps: boolean = false;

  fechaIniImpu: string
  fechaFinImpu: string
  rfcProveImpu: string
  LoadingImpu: boolean = false;

  cmbllena: any
  constructor(
    private serv: SuppliersService,
  ) { }
 
  getProveedores(){
    return this.serv.cmbProvedorDown().subscribe((resp:any)=>{
     this.cmbllena= resp;
    })
  }
  DownLoadCntr = new FormGroup({
    'fechaIniCntr': new FormControl('',Validators.required),
    'fechaFinCntr': new FormControl('',Validators.required),
    'rfcProveCntr': new FormControl('',Validators.required),
  
  });
  DownLoadImss = new FormGroup({
    'fechaIniImss': new FormControl('',Validators.required),
    'fechaFinImss': new FormControl('',Validators.required),
    'rfcProveImss': new FormControl('',Validators.required),
  
  });
  DownLoadInfo = new FormGroup({
    'fechaIniInfo': new FormControl('',Validators.required),
    'fechaFinInfo': new FormControl('',Validators.required),
    'rfcProveInfo': new FormControl('',Validators.required),
  
  });
  DownLoadNomi = new FormGroup({
    'fechaIniNomi': new FormControl('',Validators.required),
    'fechaFinNomi': new FormControl('',Validators.required),
    'rfcProveNomi': new FormControl('',Validators.required),
  });
  DownLoadStps = new FormGroup({
    'fechaIniStps': new FormControl('',Validators.required),
    'fechaFinStps': new FormControl('',Validators.required),
    'rfcProveStps': new FormControl('',Validators.required),
  });
  DownLoadImpu = new FormGroup({
    'fechaIniImpu': new FormControl('',Validators.required),
    'fechaFinImpu': new FormControl('',Validators.required),
    'rfcProveImpu': new FormControl('',Validators.required),
  });
  DownCrtn(){
    if(this.DownLoadCntr.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingCntr =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadContrato(this.DownLoadCntr.controls.rfcProveCntr.value,this.fechaIniCntr,this.fechaFinCntr).subscribe((resp:any)=>{
      console.log(resp);
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingCntr =false;
    })
  }
  DownImss(){
    if(this.DownLoadImss.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingImss =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadImss(this.DownLoadImss.controls.rfcProveImss.value,this.fechaIniImss,this.fechaFinImss).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingImss =false;
    })
  }
  DownInfo(){
    if(this.DownLoadInfo.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingInfo =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadInfo(this.DownLoadInfo.controls.rfcProveInfo.value,this.fechaIniInfo,this.fechaFinInfo).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingInfo =false;
    })
  }
  DownNomi(){
    if(this.DownLoadNomi.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingNomi =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadNomi(this.DownLoadNomi.controls.rfcProveNomi.value,this.fechaIniNomi,this.fechaFinNomi).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingNomi =false;
    })
  }
  DownStps(){
    if(this.DownLoadStps.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingStps =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadStps(this.DownLoadStps.controls.rfcProveStps.value,this.fechaIniStps,this.fechaFinStps).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingStps =false;
    })
  }
  DownImpu(){
    if(this.DownLoadImpu.invalid){
      alert("Campo requerido vacio.");
      return;
    }
    this.LoadingImpu =true
    // console.log('rfcProveCntr');
    return this.serv.donwloadImpu(this.DownLoadImpu.controls.rfcProveImpu.value,this.fechaIniImpu,this.fechaFinImpu).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.LoadingImpu =false;
    })
  }
  ngOnInit(): void {
    this.getProveedores();
  }

}
