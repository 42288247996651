import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ApscreenComponent } from './components/apscreen/apscreen.component';
import { TaxscreenComponent } from './components/taxscreen/taxscreen.component';
import { ApcapturaComponent } from './components/apcaptura/apcaptura.component';
import { RhscreenComponent } from './components/rhscreen/rhscreen.component';
import { RegistroComponent } from './components/registro/registro.component';
import { AvisoComponent } from './components/aviso/aviso.component';
import { QuestionarioComponent } from './components/questionario/questionario.component';
import { VistacuestionComponent } from './components/vistacuestion/vistacuestion.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { PermisosGuard } from './guards/permisos.guard';
import { RolusersGuard } from 'src/app/guards/rolusers.guard';
import { DownloadComponent } from './components/download/download.component';
import { DeleterequestComponent } from './components/deleterequest/deleterequest.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  { path: '',component: HomeComponent,
    children:[
      { path: 'documents',component: DocumentsComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['PR']
      }
      },
      // { path: 'apscreen',component: ApscreenComponent
      // ,canActivate:[RolusersGuard]
      // ,data:{
      //   allowedRoles:['PR']
      // }
      // },
      { path: 'taxcreen',component: TaxscreenComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['TA','RH']
      }
      },
      { path: 'apcaptur',component: ApcapturaComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['AP']
      }
      },
      { path: 'rhscreen',component: RhscreenComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['RH']
      }
      },
      { path: 'aviso',component: AvisoComponent},
      { path: 'questionario',component: QuestionarioComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['PR','TA']
      }
      },
      { path: 'consultacues',component: VistacuestionComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['TA']
      }
      },
      { path: 'proveedores',component: SuppliersComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['TA','RH']
      }
      },
      { path: 'descargas',component: DownloadComponent
      ,canActivate:[RolusersGuard]
      ,canLoad:[RolusersGuard]
      ,data:{
        allowedRoles:['TA','RH']
      }
      },
      {path: 'delete',component: DeleterequestComponent
        ,canActivate:[RolusersGuard]
        ,canLoad:[RolusersGuard]
        ,data:{
          allowedRoles:['RH']
        }  
        
      }

    ]
   }
  
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
