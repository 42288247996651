
<ngx-spinner bdColor = "rgba(192,192,192,0.3)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Procesando... </p>
</ngx-spinner>
<div *ngIf="factura==3">Token is Invalid, vuelva a loguearse.</div>
<div class="row">
      <!-- <div><h1>No tienes documentación pedientes de subir.</h1></div> -->
      <div  *ngIf="factura==0" class="alert alert-light" role="alert">
            <h1 class="justify-content-center"> <span  class="badge badge-info">¡No tienes documentación pediente de subir!</span></h1>
      </div>
      
      <div *ngIf="factura==1" class="col-md-12">
                  <div class="row">
                        <div class="col-md-4">
                              <div class="form-group input-group-sm">
                                    <h3 class="justify-content-center"> <span  class="badge badge-warning">Tienes documentos solicitados sin subir. Clic en el combo</span></h3>
                                    <label>Seleciona el mes a subir</label>
                                    <select [(ngModel)]="mes" 
                                    (change)="onChangeCmb($event)"                                 
                                    name="mes"
                                    id= "mes"
                                    class="form-control"
                                    style="width: 100%;">
                                         
                                          <option value={{mes.aniomes}} *ngFor="let mes of invoices">
                                                {{mes.meses}}
                                          </option>
                                         
                                    </select>
                              </div>  
                        </div>
                        <div class="col-md-6">
                              
                        </div>
                       
                        
                  </div>

                  <div class="row">
                        <div *ngIf="docIms==0" class="col-6">
                              <div class="info-box">
                                    <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">
                                    <!-- <span class="info-box-text">Uploads</span>
                                    <span class="info-box-number">13,648</span> -->
                                          <form [formGroup]="myForm" (ngSubmit)="submit()">

                                                <div class="form-group">
                                                      <label for="file">Copia de las cuotas obrero patronales al IMSS</label>
                                                      <input 
                                                            formControlName="file"
                                                            id="file" 
                                                            type="file" 
                                                            class="form-control"
                                                            accept="application/pdf"
                                                            (change)="onFileChange($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                    
                                          </form>
                                    </div>
                                    
                              </div>
                        </div>
                        <div *ngIf="docTax==0" class="col-6">   
                              <div class="info-box">
                                    <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">                             
                                          <form [formGroup]="myFormTax" (ngSubmit)="submittax()">
                              
                                                <div class="form-group">
                                                <label for="tax">Copia del pago expedido por institución bancaria del entero de retenciones de impuestos por sueldos</label>
                                                <input 
                                                      formControlName="file"
                                                      id="file" 
                                                      type="file" 
                                                      class="form-control"
                                                      accept="application/pdf"
                                                      (change)="onFileChangeTax($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                    
                                          </form>
                                    </div>
                              
                              </div>
                        </div>                       
                  </div>
                  <hr>
                  <div  class="row">
                        <div  *ngIf="docInf==0" class="col-6">   
                              <div class="info-box">
                                    <span class="info-box-icon badge-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">                             
                                          <form [formGroup]="myFormInf" (ngSubmit)="submitinfona()">
                              
                                                <div class="form-group">
                                                <label>Copia del pago al INFONAVIT</label>
                                                <input 
                                                      formControlName="file"
                                                      id="file" 
                                                      type="file" 
                                                      class="form-control"
                                                      accept="application/pdf"
                                                      (change)="onFileChangeInfona($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                     
                                          </form>
                                    </div>
                              
                              </div>
                        </div>
                        <div *ngIf="docStp==0" class="col-6">   
                              <div class="info-box">
                                    <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">                             
                                          <form [formGroup]="myFormStp" (ngSubmit)="submitstps()">
                              
                                                <div class="form-group">
                                                <label>Copia del registro ante la STPS</label>
                                                <input 
                                                      formControlName="file"
                                                      id="file" 
                                                      type="file" 
                                                      class="form-control"
                                                      accept="application/pdf"
                                                      (change)="onFileChangeStps($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                    
                                          </form>
                                    </div>
                              
                              </div>
                        </div>
                      
                  </div>
                  <hr>
                  <div  class="row">
                        <div *ngIf="docNom==0" class="col-6">   
                              <div class="info-box">
                                    <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">                             
                                          <form [formGroup]="myFormNom" (ngSubmit)="submitnomina()">
                              
                                                <div class="form-group">
                                                <label>Copia de comprobantes fiscales por salarios de los trabajadores que hayan proporcionado el servicio</label>
                                                <input 
                                                      formControlName="file"
                                                      id="file" 
                                                      type="file" 
                                                      class="form-control"
                                                      accept="application/pdf"
                                                      (change)="onFileChangeNom($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                    
                                          </form>
                                    </div>
                              
                              </div>
                        </div>
                        <div *ngIf="docCntr==0" class="col-6">   
                              <div class="info-box">
                                    <span class="info-box-icon bg-info"><i class="far fa-copy"></i></span>

                                    <div class="info-box-content">                             
                                          <form [formGroup]="myFormCntr" (ngSubmit)="submitcontr()">
                              
                                                <div class="form-group">
                                                <label>Copia de contrato</label>
                                                <input 
                                                      formControlName="file"
                                                      id="file" 
                                                      type="file" 
                                                      class="form-control"
                                                      accept="application/pdf"
                                                      (change)="onFileChangeCntr($event)">
                                                </div>                                         
                                                <button class="float-right btn btn-outline-info" type="submit">Cargar</button>
                                    
                                          </form>
                                    </div>
                              
                              </div>
                        </div>                       
                  </div>
      </div>
      
</div>
<div class="row">
      <div class="col-md-12"> 
            <div class="card">
                        <div class="card-header border-transparent">
                              <!-- <h1 class="card-title">.</h1> -->
                              <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6"><h1 class="card-title">Documentos solcitados</h1></div>
                                    <div class="col-md-3"></div>
                              </div>
                        </div>  
                      <div class="card-body p-0">
                            <div class="table-responsive">
                              <table  datatable [dtOptions]="dtSuppliers" [dtTrigger]="dtTriggerSu" class="table table-bordered table-hover">
                                <thead>
                                    <tr>                                  
                                        <th>Razón social</th>
                                        <th>RFC emisor</th>
                                        <th>Factura</th>
                                        <th>Mes</th>
                                        <th>Contrato</th>
                                        <th>IMSS</th>
                                        <th>Impuestos</th>
                                        <th>INFONAVIT</th>
                                        <th>Nómina</th>
                                        <th>STPS</th>
                                        <th>Planta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                      <tr *ngFor="let fa of facturas">
                                            <td>{{fa.nombre}}</td>
                                            <td>{{fa.rfc}}</td>
                                            <td>{{fa.folio}}</td>
                                            <td>{{fa.aniomes}}</td>
                                            <td>
                                                <p *ngIf="fa.valcntr ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valcntr ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valcntr ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valcntr ==null" class="text-warning">Sin Docto</p>
                                            </td>
                                            <td>
                                                <p *ngIf="fa.valimss ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valimss ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valimss ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valimss ==null" class="text-warning">Sin Docto</p>
                                                
                                            </td>
                                            <td>
                                                <p *ngIf="fa.valimpu ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valimpu ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valimpu ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valimpu ==null" class="text-warning">Sin Docto</p>
                                            </td>
                                            <td>
                                                <p *ngIf="fa.valinfo ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valinfo ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valinfo ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valinfo ==null" class="text-warning">Sin Docto</p>
                                            </td>
                                            <td>
                                                <p *ngIf="fa.valnomi ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valnomi ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valnomi ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valnomi ==null" class="text-warning">Sin Docto</p>
                                            </td>
                                            <td>
                                                <p *ngIf="fa.valstps ==1" class="text-success">Aceptado</p>
                                                <p *ngIf="fa.valstps ==2" class="text-danger">Rechazado</p>
                                                <p *ngIf="fa.valstps ==0" class="text-info">No validado</p>
                                                <p *ngIf="fa.valstps ==null" class="text-warning">Sin Docto</p>
                                            </td>
                                            <td>{{fa.planta}}</td>
                                      </tr>
                                
                                </tbody>
                              </table>
                            </div>
                      </div>  
                
              </div>
      </div>
</div>
