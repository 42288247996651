 <!-- TABLE: LATEST ORDERS -->

 <div class="card">
    <div class="card-header">
      <h3 class="card-title">Búsqueda cuestionario</h3>
    </div>
    <!-- /.card-header -->
    <!-- form start -->
    <form [formGroup]="myQuestion">
      <div class="card-body">
        <div class="row">
            <!-- <div class="col-md-4"> 
                  <div class="form-group">
                        <label>RFC Proveedor de Servicios Especializados</label>
                        <select class="form-control select2" 
                                formControlName="rfcProve" 
                                style="width: 100%;"
                                [(ngModel)]="rfcProve">
                          <option selected="selected" value={{rfc.rfc}} *ngFor="let rfc of rfcProvee" >{{rfc.rfc}}</option>
                      
                        </select>
                  </div>
            </div> -->
            <div class="col-md-4"> 
                  <div class="form-group">
                        <label>Fecha inicial</label>
                        <div class="input-group">
                              <input matInput readonly  [matDatepicker]="picker" 
                              class="form-control"
                              formControlName="fechaIni"
                              [(ngModel)]="fechaIni"
                              placeholder="mm-dd-yyyy">
                              <div class="input-group-append" maxlength="45">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>                   
                              </div>                
                        </div>
                  </div>
            </div>
            <div class="col-md-4"> 
              <div class="form-group">
                    <label>Fecha final</label>
                    <div class="input-group">
                          <input matInput readonly  [matDatepicker]="picker2" 
                          class="form-control"
                          formControlName="fechaFin"
                          [(ngModel)]="fechaFin"
                          placeholder="mm-dd-yyyy">
                          <div class="input-group-append" maxlength="45">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>                   
                          </div>                
                    </div>
              </div>
          </div>

        </div>
          
        
      
        
      
      </div>
      <!-- /.card-body -->

      <div class="card-footer">
        <button class="float-right btn btn-outline-info" (click)="excel()">Exportar xls</button>
        <button  class="float-right btn btn-outline-info" (click)="cuestionario()">Ver</button>
        
        
      </div>
    </form>
</div>

 <div class="card ">
      <div class="card-header border-transparent">
       
        </div>
          
          
            <div class="card-body p-0">
                  <div class="table-responsive">
                    <table  datatable [dtOptions]="dtOptionstax" [dtTrigger]="dtTrigger" class="table table-bordered table-hover">
                      <thead>
                          <tr >
                          
                              <th>Pregunta</th>
                              <th>Respuesta</th>
                              <th>RFC</th>
                              <th>Planta</th>
                              <th>Fecha</th>
                              
                          </tr>
                      </thead>
                      <tbody>
                            <tr *ngFor="let resp of respuestas">
                                 
                                  <td> {{resp.pregunta}}</td>
                                  <td> {{resp.respuesta}}</td>
                                  <td> {{resp.rfc}}</td>
                                  <td> {{resp.planta}}</td>
                                  <td> {{resp.fecharReg}}</td>

                            </tr>
                      
                      </tbody>
                    </table>
                  </div>
            </div>  
     
    </div> 



     