<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-circus" [fullScreen] = "true"><p style="color: white" > Guardando... </p>
</ngx-spinner>
<div class="row">
  <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6"><h1 class="card-title">Solicitud de documentos</h1></div>
            <div class="col-md-3"></div>
          </div>         
        </div>
        <form [formGroup]="myFormAp" (ngSubmit)="submitcaptura()">
          <div class="card-body inicio">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        
                        <h3>Razón Social
                          <label *ngIf="this.myFormAp.get('razonSocial').errors?.required" class="font-weight-bold text-danger">*</label>
                        </h3>        
                        <select 
                        class="form-control select2 select2-danger" 
                        formControlName="razonSocial" 
                        data-dropdown-css-class="select2-danger" 
                        style="width: 100%;"
                        maxlength="200"
                        (change)="onchangeCmb($event.target.value)">
                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                        <option value={{provee.name}} *ngFor="let provee of cmbllena"> {{provee.name }} </option>  
                        </select>
                      
                    </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">                    
                     <h3>RFC Proveedor<label *ngIf="this.myFormAp.get('rfc').errors?.required" class="font-weight-bold text-danger">*</label></h3>               
                      <input type="text" 
                      readonly
                      formControlName="rfc"
                      class="form-control" 
                      placeholder="RFC"
                      maxlength="20"
                      [(ngModel)]="rfc"
                      >
                    
                  </div>                 
                </div>
                <div class="col-md-3">
                  <div class="form-group">                    
                     <h3>RFC Receptor<label *ngIf="this.myFormAp.get('rfcReceptor').errors?.required" class="font-weight-bold text-danger">*</label></h3>               
                      <input type="text" 
                      readonly
                      formControlName="rfcReceptor"
                      class="form-control" 
                      placeholder="Receptor"
                      maxlength="20"
                      [(ngModel)]="rfcReceptor"
                      >
                    
                  </div>                 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                     
                      <h3>Factura<label *ngIf="this.myFormAp.get('factura').errors?.required" class="font-weight-bold text-danger">*</label></h3>
                      <!-- <span *ngIf="this.myFormAp.get('factura').errors?.required" class="badge badge-danger"> 
                        *
                      </span> -->
                      <input type="text" 
                      class="form-control"
                      formControlName="factura" 
                      placeholder="Factura"
                      maxlength="45">
                    
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
 
                     <h3>Fecha de pago<label *ngIf="this.myFormAp.get('fechaFactura').errors?.required" class="font-weight-bold text-danger">*</label></h3> 
                      <div class="input-group">
                          <input matInput readonly  [matDatepicker]="picker" 
                          class="form-control"
                          formControlName="fechaFactura"
                          placeholder="Fecha factura">
                          <div class="input-group-append" maxlength="45">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>                   
                          </div>                
                      </div>
                    
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                   
                   <h3>Total<label *ngIf="this.myFormAp.get('total').errors?.required" class="font-weight-bold text-danger">*</label></h3> 
                    <!-- <span *ngIf="this.myFormAp.get('total').errors?.required" class="badge badge-danger"> 
                      *
                    </span> -->
                    <input type="text" 
                    class="form-control"
                    formControlName="total" 
                    placeholder="Total"
                     currencyMask 
                    [options]="{ prefix: '$ ', thousands: ',', decimal: '.' }"
                    maxlength="20">
                  
                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <h3>Planta<label *ngIf="this.myFormAp.get('planta').errors?.required" class="font-weight-bold text-danger">*</label></h3> 
                  <!-- <select 
                    class="form-control select2 select2-danger" 
                    formControlName="planta" 
                    data-dropdown-css-class="select2-danger" 
                    style="width: 100%;"
                    maxlength="200"> -->
                    <input type="text" 
                    readonly
                    formControlName="planta"
                    class="form-control" 
                    placeholder="Planta"
                    maxlength="20"
                    [(ngModel)]="planta"
                    >
                    <!-- <option value="" placeholder="Selecciona la planta">Selecciona la planta</option> 
                    <option value={{planta.planta}} *ngFor="let planta of plantas"> {{planta.planta}} </option>    -->
                    <!-- <option value="ATZ">ATZ</option>
                    <option value="CEL">CEL</option>
                    <option value="CON">CON</option>
                    <option value="CUA">CUA</option>
                    <option value="DEL">DEL</option>
                    <option value="GDL">GDL</option>
                    <option value="JUA">JUA</option>
                    <option value="MTY">MTY</option>
                    <option value="SLT">SLT</option>
                    <option value="SJR">SJR</option>
                    <option value="SLP">SLP</option>
                    <option value="SCL">SCL</option>
                    <option value="TIJ">TIJ</option> -->
                  <!-- </select> -->
                </div>
              </div>
             
            </div>  
            <div>  <p class="text-right text-danger">*Campo requerido </p></div>           
          </div>
          <!-- /.card-body -->
          
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" >Guardar</button>
          </div>
        </form>
  </div>
  </div>
</div>
<div class="row"> 
  <div class="col-md-12"> 
      <div class="card ">
          <div class="card-header border-transparent">
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6"><h1 >Solicitados</h1></div>
                <div class="col-md-3"></div>
              </div>
            </div>
              
              
                <div class="card-body p-0">
                      <div class="table-responsive">
                        <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-hover">
                          <thead>
                              <tr >
                              
                                  <th>Razón Social</th>
                                  <th>RFC</th>
                                  <th>Factura</th>
                                  <th>Fecha de pago</th>
                                  <th>Total</th>
                              </tr>
                          </thead>
                          <tbody>
                                <tr *ngFor="let ap of apCaptura">
                                      <td>{{ap.nombre}}</td>
                                      <td>{{ap.rfc}}</td>
                                      <td>{{ap.folio}}</td>
                                      <td>{{ap.fechaTimbrado}}</td>
                                      <td>{{ap.total}}</td>
                                      
                                </tr>
                          
                          </tbody>
                        </table>
                      </div>
                </div>  
         
        </div>
  </div>
</div>
