 <!-- TABLE: LATEST ORDERS -->

 <div class="card">
    <div class="card-header">
      <h3 class="card-title">Búsqueda</h3>
    </div>
    <!-- /.card-header -->
    <!-- form start -->
    <form [formGroup]="myFormTax">
      <div class="card-body">
        <div class="row">
            <div class="col-md-2"> 
                  <div class="form-group">
                        <label>RFC P.S.E</label>
                        <select class="form-control select2" 
                                formControlName="rfcProve" 
                                style="width: 100%;"
                                [(ngModel)]="rfcProve">
                          <option selected="selected" value={{rfc.rfc}} *ngFor="let rfc of rfcProvee" >{{rfc.rfc}}</option>
                      
                        </select>
                  </div>
            </div>
            <div class="col-md-2"> 
                  <div class="form-group">
                        <label>Fecha inicial</label>
                        <div class="input-group">
                              <input matInput readonly  [matDatepicker]="picker" 
                              class="form-control"
                              formControlName="fechaIni"
                              [(ngModel)]="fechaIni"
                              placeholder="mm-dd-yyyy">
                              <div class="input-group-append" maxlength="45">
                                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker #picker></mat-datepicker>                   
                              </div>                
                        </div>
                  </div>
            </div>
            <div class="col-md-2"> 
              <div class="form-group">
                    <label>Fecha final</label>
                    <div class="input-group">
                          <input matInput readonly  [matDatepicker]="picker2" 
                          class="form-control"
                          formControlName="fechaFin"
                          [(ngModel)]="fechaFin"
                          placeholder="mm-dd-yyyy">
                          <div class="input-group-append" maxlength="45">
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>                   
                          </div>                
                    </div>
              </div>
          </div>
          <div class="col-md-1">
              <br>
              <button  class="float-right btn btn-outline-info" (click)="vistaimpuesto()">Buscar</button>
          </div>
          <div class="col-md-2">
            <button class="float-right btn btn-outline-info" (click)="excel()">Exportar xls</button>
          </div>

        </div>
        
         
        
      
        
      
      </div>
      <!-- /.card-body -->

      
    </form>
    <hr>
    <form [formGroup]="myFormPla">
      <div class="card-body">
        <div class="row">
            
          <div class="col-md-2">
            <div class="form-group">
                
                <!-- <h3>Planta -->
                  <!-- <label *ngIf="this.myFormAp.get('razonSocial').errors?.required" class="font-weight-bold text-danger">*</label> -->
                <!-- </h3>         -->
                <select 
                class="form-control select2 select2-danger" 
                formControlName="planta" 
                data-dropdown-css-class="select2-danger" 
                style="width: 100%;"
                maxlength="200"
                [(ngModel)]="planta">
                >
                <option value="" placeholder="Selecciona la planta">Selecciona la planta</option>  
                <option value={{planta.Planta}} *ngFor="let planta of cmbPlanta"> {{planta.Planta }} </option>  
                </select>
              
          
          </div>

        </div>
        <div class="col-md-2">
          <button class="float-right btn btn-outline-info" (click)="excelSinDocto()">Prov sin docto</button>
        </div>
        

        </div>
      </div>
    </form>
    
</div>

  <div class="card ">
      <div class="card-header border-transparent">
          <!-- <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6"><h1 >Capturados Recientes</h1></div>
            <div class="col-md-3"></div>
          </div> -->
        </div>
          
          
            <div class="card-body p-0">
                  <div class="table-responsive">
                    <table  datatable [dtOptions]="dtOptionstax" [dtTrigger]="dtTrigger" class="table table-bordered table-hover">
                      <thead>
                          <tr >
                          
                              <th>Folio</th>
                              <th>RFC</th>
                              <th>Razon Social</th>
                              <th>Total</th>
                              <th>Fecha Pago</th>
                              <th>Planta</th>
                              <th>PDF_Contra</th>
                              <th>PDF_IMSS</th>
                              <!-- <th>Fecha carga</th>
                              <th>Validado</th> -->
                              <th>PDF_TAX</th>
                              <!-- <th>Fecha carga</th>
                              <th>Validado</th> -->
                              <th>PDF_STPS</th>
                              <!-- <th>Fecha carga</th>
                              <th>Validado</th> -->
                              <th> PDF_INFONAVIT</th>
                              <!-- <th>Fecha carga</th>
                              <th>Validado</th> -->
                              <th>PDF_Nomina</th>
                              <!-- <th>Fecha carga</th>
                              <th>Validado</th> -->
                          </tr>
                      </thead>
                      <tbody>
                            <tr *ngFor="let ta of doctos">
                                 
                                  <td> {{ta.folio}}</td>
                                  <td> {{ta.rfc}}</td>
                                  <td> {{ta.nombre}}</td>
                                  <td> {{ta.total}}</td>
                                  <td> {{ta.fechaTimbrado}}</td>
                                  <td> {{ta.planta}}</td>
                                  <td> 
                                        <div class="row">
                                          <div class="col-md-6">
                                              <button type="button"  
                                              *ngIf="ta.existeCONT=='1'"                                 
                                              class="btn btn-outline-danger fas fa-file-pdf"
                                              (click)="taxDocCntr(ta.aniomes,ta.rfc,ta.idcntr)">
                                            </button>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                            <label *ngIf="ta.validadoContrato == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                            <label *ngIf="ta.validadoContrato == 1" class="form-check-label badge badge-success">Aceptado</label>
                                            <label *ngIf="ta.validadoContrato == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                            <label *ngIf="ta.validadoContrato == null" class="form-check-label badge badge-warning">Sin docto</label>
                                          </div>
                                        </div>
                                    
                                   
                                  </td>
                                  <td> 
                                      <div class="row">
                                        <div class="col-md-6">
                                          <button type="button"  
                                          *ngIf="ta.existeIMSS=='1'"                                 
                                          class="btn btn-outline-danger fas fa-file-pdf"
                                          (click)="taxDocIMSS(ta.aniomes,ta.rfc,ta.idimss)">
                                          </button>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-6">
                                          <label *ngIf="ta.validadoImss == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                          <label *ngIf="ta.validadoImss == 1" class="form-check-label badge badge-success">Aceptado</label>
                                          <label *ngIf="ta.validadoImss == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                          <label *ngIf="ta.validadoImss == null" class="form-check-label badge badge-warning">Sin docto</label>
                                        </div>
                                      </div>
                                  </td>
                                  <!-- <td> {{ta.fechaCargaImss}}</td>
                                  <td> {{ta.validadoImss}}</td> -->
                                  <td> 
                                    <div class="row">
                                      <div class="col-md-6">
                                        <button type="button" 
                                        *ngIf="ta.existeIMPU=='1'" 
                                        class="btn btn-outline-danger fas fa-file-pdf"
                                        (click)="taxDocTaxs(ta.aniomes,ta.rfc,ta.idimpu)">
                                        </button>
                                      </div>
                                   </div>
                                   <div class="row">
                                    <div class="col-md-6">
                                      <label *ngIf="ta.validadoTax == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                      <label *ngIf="ta.validadoTax == 1" class="form-check-label badge badge-success">Aceptado</label>
                                      <label *ngIf="ta.validadoTax == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                      <label *ngIf="ta.validadoTax == null" class="form-check-label badge badge-warning">Sin docto</label>
                                    </div>
                                   </div>
                                  </td>
                                  <!-- <td> {{ta.fechaCargaTax}}</td>
                                  <td> {{ta.validadoTax}}</td> -->
                                  <td> 
                                    <div class="row">
                                      <div class="col-md-6">
                                        <button type="button" 
                                        *ngIf="ta.existeSTPS=='1'"
                                        (click)="downPdfStps(ta.aniomes,ta.rfc,ta.idstps)"
                                        class="btn btn-outline-danger fas fa-file-pdf">
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <label *ngIf="ta.validadoSTPS == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                      <label *ngIf="ta.validadoSTPS == 1" class="form-check-label badge badge-success">Aceptado</label>
                                      <label *ngIf="ta.validadoSTPS == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                      <label *ngIf="ta.validadoSTPS == null" class="form-check-label badge badge-warning">Sin docto</label>
                                      </div>
                                    </div>
                                    
                                    <!-- {{ta.nombreArchivoSTPS}} -->

                                  </td>
                                  <!-- <td> {{ta.fechaCargaSTPS}}</td>
                                  <td> {{ta.validadoSTPS}}</td> -->
                                  <td> 
                                    <div class="row">
                                      <div class="col-md-6">
                                        <button type="button" 
                                        *ngIf="ta.existeINFO=='1'" 
                                        (click)="downPdfInfo(ta.aniomes,ta.rfc,ta.idinfo)"
                                        class="btn btn-outline-danger fas fa-file-pdf">
                                        </button>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <label *ngIf="ta.validadoINFONAVIT == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                        <label *ngIf="ta.validadoINFONAVIT == 1" class="form-check-label badge badge-success">Aceptado</label>
                                        <label *ngIf="ta.validadoINFONAVIT == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                        <label *ngIf="ta.validadoINFONAVIT == null" class="form-check-label badge badge-warning">Sin docto</label>
                                      </div>
                                    </div>
                                    
                                    
                                    <!-- {{ta.nombreArchivoINFONAVIT}} -->
                                  </td>
                                  <!-- <td> {{ta.fechaCargaINFONAVIT}}</td>
                                  <td> {{ta.validadoINFONAVIT}}</td> -->
                                  <td> 
                                    <div class="row">
                                      <div class="col-md-6">
                                        <button type="button" 
                                        *ngIf="ta.existeNOMI=='1'" 
                                        (click)="downPdfNomi(ta.aniomes,ta.rfc,ta.idnomi)"
                                        class="btn btn-outline-danger fas fa-file-pdf"></button>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <label *ngIf="ta.validadoNomina == 0" class="form-check-label badge badge-info">Sin Validar</label>
                                        <label *ngIf="ta.validadoNomina == 1" class="form-check-label badge badge-success">Aceptado</label>
                                        <label *ngIf="ta.validadoNomina == 2" class="form-check-label badge badge-danger">Rechazado</label>
                                        <label *ngIf="ta.validadoNomina == null" class="form-check-label badge badge-warning">Sin docto</label>
                                      </div>
                                    </div>
                                    
                                   
                                  </td>
                                  <!-- <td> {{ta.fechaCargaNomina}}</td>
                                  <td> {{ta.validadoNomina}}</td> -->
                                  
                            </tr>
                      
                      </tbody>
                    </table>
                  </div>
            </div>  
     
    </div>



      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 *ngIf="valImpu==1" class="modal-title" id="modal-basic-title">Validación documento Impuestos</h4>
          <h4 *ngIf="valImms==1" class="modal-title" id="modal-basic-title">Validación documento IMSS</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
             aria-hidden="true">&times;
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="dateOfBirth">¿Estas Seguro de aceptar el documento?</label>
              <div class="input-group">
                <!-- <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
                </div> -->
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button"  *ngIf="valImpu==1" class="btn btn-outline-info" (click)="onValidarTax()">Aceptar</button>
          <button type="button"  *ngIf="valImpu==1" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
          <button type="button" *ngIf="valImms==1" class="btn btn-outline-info" (click)="onValidarIms()">Aceptar</button>
          <button type="button" *ngIf="valImms==1" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
        </div>
      </ng-template>