import { Component, OnInit,Inject } from '@angular/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';
export interface DialogData {
  Msg: String;
}

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
//   styleUrls: ['./locked.component.css']
})
export class LockedComponent implements OnInit {
  Msg: String;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 20;
  constructor(
    public dialogRef: MatDialogRef<LockedComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: DialogData,
  ) { 
    this.Msg = this.data.Msg;
  }

  ngOnInit(): void {

  }

}
