<div class="row">
    <div class="col-md-6">
        <form  [formGroup]="DownLoadCntr">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas Contratos</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="picker" 
                                                  class="form-control"
                                                  formControlName="fechaIniCntr"
                                                  [(ngModel)]="fechaIniCntr"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                      <mat-datepicker #picker></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="picker2" 
                                                class="form-control"
                                                formControlName="fechaFinCntr"
                                                [(ngModel)]="fechaFinCntr"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker2></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveCntr" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingCntr ==false" class="float-right btn btn-outline-info" (click)="DownCrtn()">Descargar</button> 
                  <div *ngIf="LoadingCntr ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
    <div class="col-md-6">
        <form  [formGroup]="DownLoadImss">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas IMSS</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="pickerImssI" 
                                                  class="form-control"
                                                  formControlName="fechaIniImss"
                                                  [(ngModel)]="fechaIniImss"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="pickerImssI"></mat-datepicker-toggle>
                                                      <mat-datepicker #pickerImssI></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="pickerImssF" 
                                                class="form-control"
                                                formControlName="fechaFinImss"
                                                [(ngModel)]="fechaFinImss"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerImssF"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerImssF></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveImss" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingImss ==false" class="float-right btn btn-outline-info" (click)="DownImss()">Descargar</button> 
                  <div *ngIf="LoadingImss ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <form  [formGroup]="DownLoadInfo">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas INFONAVIT</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="pickerInfoI" 
                                                  class="form-control"
                                                  formControlName="fechaIniInfo"
                                                  [(ngModel)]="fechaIniInfo"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="pickerInfoI"></mat-datepicker-toggle>
                                                      <mat-datepicker #pickerInfoI></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="pickerInfoF" 
                                                class="form-control"
                                                formControlName="fechaFinInfo"
                                                [(ngModel)]="fechaFinInfo"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerInfoF"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerInfoF></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveInfo" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingInfo ==false" class="float-right btn btn-outline-info" (click)="DownInfo()">Descargar</button> 
                  <div *ngIf="LoadingInfo ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
    <div class="col-md-6">
        <form  [formGroup]="DownLoadNomi">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas Nominas</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="pickerNomiI" 
                                                  class="form-control"
                                                  formControlName="fechaIniNomi"
                                                  [(ngModel)]="fechaIniNomi"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="pickerNomiI"></mat-datepicker-toggle>
                                                      <mat-datepicker #pickerNomiI></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="pickerNomiF" 
                                                class="form-control"
                                                formControlName="fechaFinNomi"
                                                [(ngModel)]="fechaFinNomi"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerNomiF"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerNomiF></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveNomi" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingNomi ==false" class="float-right btn btn-outline-info" (click)="DownNomi()">Descargar</button> 
                  <div *ngIf="LoadingNomi ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <form  [formGroup]="DownLoadStps">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas STPS</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="pickerStpsI" 
                                                  class="form-control"
                                                  formControlName="fechaIniStps"
                                                  [(ngModel)]="fechaIniStps"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="pickerStpsI"></mat-datepicker-toggle>
                                                      <mat-datepicker #pickerStpsI></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="pickerStpsF" 
                                                class="form-control"
                                                formControlName="fechaFinStps"
                                                [(ngModel)]="fechaFinStps"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerStpsF"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerStpsF></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveStps" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingStps ==false" class="float-right btn btn-outline-info" (click)="DownStps()">Descargar</button> 
                  <div *ngIf="LoadingStps ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
    <div class="col-md-6">
        <form  [formGroup]="DownLoadImpu">
            <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Descargas Impuestos</h3>
                </div>
                  <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"> 
                                      <div class="form-group">
                                            <label>Fecha inicial</label>
                                            <div class="input-group">
                                                  <input matInput readonly  [matDatepicker]="pickerImpuI" 
                                                  class="form-control"
                                                  formControlName="fechaIniImpu"
                                                  [(ngModel)]="fechaIniImpu"
                                                  placeholder="mm-dd-yyyy">
                                                  <div class="input-group-append" maxlength="45">
                                                      <mat-datepicker-toggle matSuffix [for]="pickerImpuI"></mat-datepicker-toggle>
                                                      <mat-datepicker #pickerImpuI></mat-datepicker>                   
                                                  </div>                
                                            </div>
                                      </div>
                                </div>
                                <div class="col-md-4"> 
                                    <div class="form-group">
                                            <label>Fecha final</label>
                                            <div class="input-group">
                                                <input matInput readonly  [matDatepicker]="pickerImpuF" 
                                                class="form-control"
                                                formControlName="fechaFinImpu"
                                                [(ngModel)]="fechaFinImpu"
                                                placeholder="mm-dd-yyyy">
                                                <div class="input-group-append" maxlength="45">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerImpuF"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerImpuF></mat-datepicker>                   
                                                </div>                
                                            </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    
                                        
                                        <h3>Razón Social                          
                                        </h3>        
                                        <select                           
                                        formControlName="rfcProveImpu" 
                                        >
                                        <option value="" placeholder="Selecciona el proveedor">Selecciona el proveedor</option>  
                                        <option *ngFor="let provee of cmbllena" value={{provee.rfc}} > {{provee.rfc }} </option>  
                                        </select>
                                      
                                   
                                </div>
                            </div>
                  </div>
                  <div class="card-footer">
                  <button *ngIf="LoadingImpu ==false" class="float-right btn btn-outline-info" (click)="DownImpu()">Descargar</button> 
                  <div *ngIf="LoadingImpu ==true" class="spinner-grow text-primary float-right" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                    
                  </div>
                
            </div>

        </form>
    </div>
</div>


