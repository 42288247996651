<div class="wrapper">

  <!-- Preloader -->
  <div class="preloader flex-column justify-content-center align-items-center">
    <!-- <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60"> -->
  </div>

  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <!-- <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a> -->
      </li>
     
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i (click)="onLogout()" class="fas fa-sign-out-alt">Salir</i>
        </a>
      </li>
      <!-- Notifications Dropdown Menu -->
    </ul>
  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <label class="brand-link">
      <img src="{{imageSrc}}" alt="{{imageAlt}}" class="elevation-3" style="opacity: .8; max-height: 250px">
      <!-- <h5>Proveedores de servicios especializados</h5> -->

  
    </label>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <!-- <div class="image">
        
          <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
        </div> -->
       
        <div class="info">
          <a>Bienvenido(a)</a>
          <a class="d-block">{{nombre}}</a>
        </div>
      </div>

      <!-- SidebarSearch Form -->
      <div class="form-inline">
        <!-- <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div> -->
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item menu-open">
            <a class="nav-link active">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Menú
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li *ngIf="tipo=='RH'" class="nav-item">
                <a routerLink="/rhscreen" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Validar doctos</p>
                </a>
              </li>
              <li *ngIf="tipo=='PR'" class="nav-item">
                <a routerLink="/documents" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Subir documentos</p>
                </a>
              </li>
              <li *ngIf="tipo=='TA' || tipo=='PR'" class="nav-item">
                <a routerLink="/questionario" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Cuestionario</p>
                </a>
              </li>
              <li *ngIf="tipo=='AP'" class="nav-item">
                <a routerLink="/apcaptur" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Registro de proveedores</p>
                </a>
              </li>
              <li *ngIf="tipo=='TA' || tipo=='RH'" class="nav-item">
                <a routerLink="/taxcreen" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Consulta y Reportes</p>
                </a>
              </li>
              <li *ngIf="tipo=='TA'" class="nav-item">
                <a routerLink="/consultacues" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Consulta Cuestionario</p>
                </a>
              </li>
              <li *ngIf="tipo=='TA' || tipo=='RH'" class="nav-item">
                <a routerLink="/proveedores" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Proveedores</p>
                </a>
              </li>
              <li *ngIf="tipo=='TA' || tipo=='RH'" class="nav-item">
                <a routerLink="/descargas" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>descargas</p>
                </a>
              </li>
              <li *ngIf="tipo=='RH'" class="nav-item">
                <a routerLink="/delete" routerLinkActive="active" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Eliminar solicitudes</p>
                </a>
              </li>
            </ul>
          </li>

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <!-- <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Dashboard</h1>
          </div>
          
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Dashboard v1</li>
            </ol>
          </div>
         
        </div> -->
      </div>
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <router-outlet></router-outlet>
        <!-- <div class="row">
          <div class="col-sm-12">
         
          </div>

        </div> -->
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <footer class="main-footer">
    <strong>SONOCO MÉXICO</strong>
    <!-- <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
    All rights reserved. -->
    <div class="float-right d-none d-sm-inline-block">
      <b>Versión</b> 1.2.0
    </div>
  </footer>

  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
</div>
<!-- ./wrapper -->
