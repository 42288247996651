import { Component,OnDestroy, OnInit } from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { Subject } from 'rxjs';
// import 'rxjs/add/operator/map';
@Component({
  selector: 'app-apcaptura',
  templateUrl: './apcaptura.component.html',
  styleUrls: ['./apcaptura.component.scss']
})
export class ApcapturaComponent implements OnDestroy, OnInit {
  dtOptions: DataTables.Settings = {};
  
  public apCaptura:      Array<any> =[]
  public cmbllena:      Array<any> =[]
  public plantas:      Array<any> =[]
  public rfc: string;
  public id: number;
  public planta: string;
  public rfcReceptor: string;
  dtTrigger: Subject<any> = new Subject<any>();

  myFormAp: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private scvSuppl: SuppliersService
  ) { 
    
  }

  ngOnInit(): void {
    this.cmbProveedor();
    this.ApCapturado();
    this.myFormAp = this.formBuilder.group({
      'razonSocial':new FormControl('',{validators:[Validators.required]}),
      'rfc':new FormControl('',{validators:[Validators.required]}),
      'factura':new FormControl('',{validators:[Validators.required]}),
      'fechaFactura':new FormControl('',{validators:[Validators.required]}),
      'total':new FormControl('',{validators:[Validators.required]}),
      'planta':new FormControl('',{validators:[Validators.required]}),
      'rfcReceptor':new FormControl('',{validators:[Validators.required]})
    })

    setTimeout(() => {
      this.spinner.hide();
    }, 50000);

    this.dtOptions = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 10
    };
    
  }
  submitcaptura(){
    if(this.myFormAp.invalid){
    alert("Todos los campos son requeridos.");
    return 0;
    }
  this.spinner.show();

    const{razonSocial}=this.myFormAp.value;
    const{rfc}=this.myFormAp.value;
    const{factura}=this.myFormAp.value;
    const{fechaFactura}=this.myFormAp.value;
    const{total}=this.myFormAp.value;
    const{planta}=this.myFormAp.value;
    const{rfcReceptor}=this.myFormAp.value;

    this.scvSuppl.apCaptura(razonSocial,rfc,factura,fechaFactura,total,planta,rfcReceptor).subscribe((resp:any)=>{
      this.spinner.hide();
      this. ngOnDestroy();
      this.ApCapturado();
      this.resetFormu();
      alert("Se guardó satisfactoriamente...");
    })
  }
  ApCapturado():void{
    this.id = Number(localStorage.getItem('id'))
    this.scvSuppl.apCapturado(this.id).subscribe((resp:any)=> {
    this.apCaptura=resp;
    this.dtTrigger.next();
   
    })
  }
  cmbProveedor(){
   this.id = Number(localStorage.getItem('id'))
    this.scvSuppl.cmbProv(this.id).subscribe((resp:any)=> {
      this.cmbllena=resp;
     
      })
  }
  resetFormu(){
  this.myFormAp.reset();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  onchangeCmb(val){
  //  alert(val);
  var id;
  for (let variable in this.cmbllena) {    
    // alert(this.cmbllena[variable].name);     
    if (this.cmbllena[variable].name === val) {
      // checkboxsensores.push(variable);
      // alert(this.cmbllena[variable].rfc);
      this.rfc=this.cmbllena[variable].rfc;
      this.planta=this.cmbllena[variable].planta;
      id=this.cmbllena[variable].id;
      this.rfcReceptor =this.cmbllena[variable].RFCReceptor;
      // this.scvSuppl.selectPlanta(id).subscribe((resp:any)=>{
      //   this.plantas=resp;
      // })
    }
 }
   
    this.scvSuppl.inputRfc(val).subscribe((resp:any)=> {
      // console.log(resp);
        for (const mg in resp){
          this.rfc=resp[0].rfc;
          id=resp[0].id;
          this.scvSuppl.selectPlanta(id).subscribe((resp:any)=>{
            this.plantas=resp;
          })
        }
  
      })

  }
}

export class LanguageApp {
  public static spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }
}
