import { Component,Inject,OnDestroy, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SuppliersService } from '../../services/suppliers.service';
import {environment} from 'src/environments/environment.prod';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import {catchError,map} from 'rxjs/operators';
@Component({
  selector: 'app-deleterequest',
  templateUrl: './deleterequest.component.html',
//   styleUrls: ['./deleterequest.component.scss']
})
export class DeleterequestComponent implements OnDestroy,OnInit {
    constructor()
    {}
    ngOnDestroy(): void {
    
        // this.dtTriggerSu.unsubscribe();
    }
      
    ngOnInit(): void {

    }
}
