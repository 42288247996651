import { Component, OnInit,ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { Subject } from 'rxjs';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  constructor(
    private servi: SuppliersService,
    private modalService: NgbModal,
   
  ) { }
  @ViewChild('closebutton') closebutton;
  public respuestas:      Array<any> =[]
  dtOptionsSupp: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false
  isChecked: boolean;
  closeResult = '';
  accepted: boolean;
  idSupplier: number =0;
  ngOnInit(): void {
    this.getProveedores();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  getProveedores(){
     this.servi.selectSupplier().subscribe((response: any)=>{
      this.respuestas = response;
     })
  }
  baja(resp:any,id: number,content: any){
    this.idSupplier =id;
//  console.log(resp);
//  console.log(resp.currentTarget.checked);
    // let content: any;
    let ischeck;
    ischeck =resp.currentTarget.checked;
    if (ischeck==true){
      this.open(content)
    }

  }
  cmdBaja(){
    if(this.idSupplier==0){
      alert("Selecciona el proveedor");
    }else{
      this.servi.updateSupplier(this.idSupplier).subscribe((response:any)=>{
        this. cerrar();
      })
    }
    

  }
  open(content) {
  
    // this.modalService.open(content, { size: 'sm' }); 
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
    console.log(this.closeResult);
  }
  cerrar(){
    this.accepted = false;
    this.modalService.dismissAll();
    this.getProveedores();
  }
  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
