import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {environment} from 'src/environments/environment.prod';
// import {UserResponse,User} from '../interfaces/user.interface';
import { UserResponse,User } from '../Interfaces/user.interface';
import {catchError,map} from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient,
    private router: Router
    ) {
     this.checkToken();
   }

  get isLogged():Observable<boolean>{
    return this.loggedIn.asObservable();
  } 
  login(authData:User): Observable<UserResponse | void>{
    let header = new HttpHeaders()
    .set('Type-content','aplication/json')
     return this.http
     .post<UserResponse>(`${environment.API_URL}/login`,authData
     ,{
      headers : header
    })
     .pipe(
       map((res:UserResponse)=>{
         this.saveToken(res.access_token);
         localStorage.setItem('id',String(res.id_user));
         localStorage.setItem('name',String(res.name));
         localStorage.setItem('rfc',String(res.rfc));
         localStorage.setItem('tipo',String(res.tipo));
         localStorage.setItem('acepta',String(res.acepta));
         localStorage.setItem('user',String(authData.email));
         this.loggedIn.next(true);
         return res;
       } ),
       catchError((err)=> this.handleError(err))
     );
  }
  logout():void{
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('name');
    localStorage.removeItem('rfc');
    localStorage.removeItem('tipo');
    localStorage.removeItem('acepta');
    localStorage.removeItem('user');
    this.loggedIn.next(false);
    this.router.navigate([""]);
  }
  private checkToken():void{
    const userToken = localStorage.getItem('token');
    const isExpired =helper.isTokenExpired(userToken);
    console.log("checkToken");
    if (isExpired){
      this.logout();

    }else{
      this.loggedIn.next(true);
    }
  }
  private saveToken(token: string):void{
    localStorage.setItem('token',token);
  
  }
  private handleError(err):Observable<never>{
    let errorMessage='An error occured retrieving data';
    if (err){
      errorMessage=`Error: code ${err.message}`;
      window.alert(errorMessage);
      return throwError(errorMessage);
      
    }
  }

}
