<div class="card ">
      <div class="card-header border-transparent">
      <strong> Proveedores activos</strong>
      </div>
        
        
          <div class="card-body p-0">
                <div class="table-responsive">
                  <table  datatable [dtOptions]="dtOptionsSupp" [dtTrigger]="dtTrigger" class="table table-bordered table-hover">
                    <thead>
                        <tr >
                        
                            <th>Razon social</th>
                            <th>RFC</th>
                            <th>Correo</th>
                            <th>Estatus</th>
                            <th>Acciones</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                          <tr *ngFor="let resp of respuestas">
                               
                                <td> {{resp.name}}</td>
                                <td> {{resp.rfc}}</td>
                                <td> {{resp.email}}</td>
                                <td> {{resp.status}}</td>
                                <td>
                                    <div  class="form-check">
                                        <input class="form-check-input" [checked]="accepted"  type="checkbox" (change)="baja($event,resp.id,content)">
                                        <label class="form-check-label">Baja</label>
                                      </div>
                                    
                                </td>

                          </tr>
                    
                    </tbody>
                  </table>
                </div>
          </div>  
   
  </div>
  <ng-template #content let-modal>
        <div class="modal-header">
            <h4  class="modal-title" id="modal-basic-title">Baja de proveedores</h4>
            <!-- <button type="button" #closebutton class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button> -->
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                  <label class="form-check-label">¿Estas seguro de dar de baja?</label>
                  <div class="input-group">
        
                  </div>
                </div>
              </form>
            
        </div>
        <div class="modal-footer">
            <button type="button"  class="btn btn-outline-danger" (click)="cmdBaja()">Baja</button>
            <button type="button"  class="btn btn-outline-dark" (click)="cerrar()">Cancelar</button>
        </div>
    

  </ng-template>
