import { Component, OnInit, Inject } from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
@Component({
  selector: 'app-apscreen',
  templateUrl: './apscreen.component.html',
  styleUrls: ['./apscreen.component.scss']
})
export class ApscreenComponent implements OnInit {
  public facturas:      Array<any> =[]

  constructor(
    private scvSuppl: SuppliersService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.apInvoices();
  }
  apInvoices(){
    this.scvSuppl.apFacturas().subscribe((resp: any) =>{
      this.facturas = resp;
      if (resp){
       
      }
    })
  }
  apNotiProvee(uuid: string){
   let dialogRef= this.dialog.open(DialogComponent,
      {
        data: uuid,
        width: "400px",
        height:"210px"
      },
     
      
      );
      dialogRef.afterClosed()
  }
  


}
