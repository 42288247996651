import { Component, OnInit } from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-vistacuestion',
  templateUrl: './vistacuestion.component.html',
  // styleUrls: ['./vistacuestion.component.scss']
})
export class VistacuestionComponent implements OnInit {
  myQuestion: FormGroup;

  fechaIni: string
  fechaFin: string
  public respuestas:      Array<any> =[]
  dtOptionstax: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized:boolean = false
  constructor(
    private scvSuppl: SuppliersService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) { }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.myQuestion = this.formBuilder.group({
      'fechaIni':new FormControl('',{validators:[Validators.required]}),
      'fechaFin':new FormControl('',{validators:[Validators.required]})
      // 'rfcProve':new FormControl('',{validators:[Validators.required]})
    })
    this.dtOptionstax = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 10
    };
  }
  cuestionario(){
    // alert(this.fechaIni);
    this.scvSuppl.selectQuestion(this.fechaIni,this.fechaFin).subscribe((resp: any) =>{
      this.respuestas = resp;
      // console.log(this.respuestas);
      if(this.isDtInitialized){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
         //  this.dtTrigger.unsubscribe();
          dtInstance.destroy();
          this.datatable();
         this.dtTrigger.next();
          
      });
     }else {
       this.isDtInitialized = true
       this.dtTrigger.next();
     }
      if (resp){
       
      }
    })
  }
 
  excel(){
    if(this.myQuestion.invalid){
      alert("Todos los campos son requeridos.");
      return 0;
      }
     
    this.scvSuppl.exportResp(this.fechaIni,this.fechaFin).subscribe((resp:any)=>{
      let blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
        // window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }

 
  
 
  
 
    datatable(){
      this.dtOptionstax = {
          language: LanguageApp.spanish_datatables,
          pagingType: 'full_numbers',
          pageLength: 10
        };
    }
 
  


  




}
export class LanguageApp {
  public static spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }
}
