<p>Aceptar las facturas pagadas</p>
<div class="row">
    <table class="table table-sm">
        <thead>
        <tr>
        <th scope="col">Uuid</th>
        <th scope="col" >Cliente</th>
        <th scope="col" >RFC</th>
        <th scope="col" >Factura</th>
        <th scope="col" >SubTotal</th>
        <th scope="col" >Total</th>
        <th scope="col" >Fecha</th>
        <th scope="col" >Acciones</th>
        <!-- <th scope="col" >Ver</th> -->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let notas of facturas" >
        <th scope="row">{{notas.uuid}}</th>
        <td scope="row">
          <span class="badge badge-warning">{{notas.nombre}}</span>
        </td>
        <td scope="row">
          <span class="badge badge-warning">{{notas.rfc}}</span>
        </td>
        <td><span class="badge badge-warning">{{notas.serie}}{{notas.folio}}</span></td>
        <td><span class="badge badge-info">{{notas.subtotal}}</span></td>
        <td><span class="badge badge-info">{{notas.total}}</span></td>
        <td><span class="badge badge-warning">{{notas.fechaTimbrado}}</span></td>
        <td>
            <button type="button" class="btn btn-block btn-default btn-sm fa fa-fw fa-envelope" (click)="apNotiProvee(notas.uuid)"></button>
        </td>
      
        </tr>
        </tbody>
  </table> 

</div>