<!-- <p>Se enviará correo al Proveedor</p>
<div class="alert alert-info" role="alert">
<p>¿Estas seguro de hacer el proceso?</p>

</div> -->

<div class="card" style="width: 18rem;">
    <div class="card-body">
      <!-- <h5 class="card-title">Proveedores</h5> -->
      <h3 class="card-subtitle mb-2 text-muted">Se enviará correo al Proveedor</h3>
      <p class="card-text">¿Estas seguro de hacer el proceso?</p>
      <div class="row">
            <div class="col">
                 <button (click)="onCloseClick()" class="btn btn-outline-secondary">Cancelar</button>
            </div>
            <div class="col">
                <button (click)="enviarEmail()" class="btn btn-outline-secondary">Aceptar</button>
            </div>
      </div>
     
      
    </div>
  </div>