import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,RouterStateSnapshot,CanLoad,CanActivate, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolusersGuard implements CanActivate {
  verdad: boolean =false;
  canLoad(
    route: Route): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const allowedRoles = route.data?.['allowedRoles'];
      console.log('allowedRoles:',allowedRoles);
      let rol = String(localStorage.getItem("Rol")) ;
      this.verdad=false;
      if(allowedRoles.includes(rol)){
        console.log("includes:",route.data?.['allowedRoles']);
        console.log("Rol:",rol);
        this.verdad=true;
        alert("canLoad");
      }
    return this.verdad;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const allowedRoles = route.data?.['allowedRoles'];
      let rol = String(localStorage.getItem("tipo")) ;
      this.verdad=false;
      if(allowedRoles.includes(rol)){
        console.log("includess",route.data?.['allowedRoles']);
        console.log("Roles:",rol);
        this.verdad=true;
       
      }else{
        // this.abreModalAlert("Acceso denegado.","0")
        alert("Acceso denegado" );
      }
      // alert("canActivate:"+ this.verdad );
    return this.verdad;
  }
  
}
