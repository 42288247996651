import { Component,OnDestroy, OnInit } from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html'
//   styleUrls: ['./rhscreen.component.scss']
})
export class QuestionarioComponent implements OnInit {
    public  userRfc: string
    public  userIds: string
    public  user: string
    public questions:      Array<any> =[]
    public plantaQue:      Array<any> =[]
    myQuestion: FormGroup;
    myFormAp: FormGroup;

    question1: string;
    question2: string;
    question3: string;
    question4: string;
    question5: string;
    question6: string;
    question7: string;
  constructor(
    private scvSuppl: SuppliersService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
    // private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  //  this.Question();
   
   this.userRfc=localStorage.getItem("rfc")
   this.userIds=localStorage.getItem("id")
   this.user=localStorage.getItem("user")
   this.getPlanta();
   this.myQuestion = this.formBuilder.group({
    'Planta':new FormControl('',{validators:[Validators.required]}),
    'QUno':new FormControl('',{validators:[Validators.required]}),
    'QDos':new FormControl('',{validators:[Validators.required]}),
    'QTres':new FormControl('',{validators:[Validators.required]}),
    'QCuatro':new FormControl('',{validators:[Validators.required]}),
    'QCinco':new FormControl('',{validators:[Validators.required]}),
    'QSeis':new FormControl('',{validators:[Validators.required]}),
    'QSiete':new FormControl('',{validators:[Validators.required]})
  })

  setTimeout(() => {
    this.spinner.hide();
  }, 50000);
  }
  Question(){
     this.scvSuppl.preguntas().subscribe((resp:any)=> {
      this.questions=resp;
      this.question1=resp[0].pregunta
      this.question2=resp[1].pregunta
      this.question3=resp[2].pregunta
      this.question4=resp[3].pregunta
      this.question5=resp[4].pregunta
      this.question6=resp[5].pregunta
      this.question7=resp[6].pregunta
        return 0;
       })       
   }
  getPlanta(){
    // alert(this.userRfc);
    this.scvSuppl.plantaque(localStorage.getItem("user"),localStorage.getItem("rfc")).subscribe((resp:any)=>{
      this.plantaQue = resp;
    })

  } 
  resetFormu(){
    this.myQuestion.reset();
    }
  SaveRespuesta(){
    if(this.myQuestion.invalid){
      alert("Todos los campos son requeridos.");
      return 0;
      }
    this.spinner.show();
    const{Planta}=this.myQuestion.value;
    const{QUno}=this.myQuestion.value;
    const{QDos}=this.myQuestion.value;
    const{QTres}=this.myQuestion.value;
    const{QCuatro}=this.myQuestion.value;
    const{QCinco}=this.myQuestion.value;
    const{QSeis}=this.myQuestion.value;
    const{QSiete}=this.myQuestion.value;
    this.scvSuppl.insertQ(QUno,QDos,QTres,QCuatro,QCinco,QSeis,QSiete,this.userRfc,this.user,Planta).subscribe((resp:any)=>{
    console.log(resp);  
    this.spinner.hide();
      // this. ngOnDestroy();
      // this.ApCapturado();
      this.resetFormu();
      this.getPlanta();
      alert("Se guardó satisfactoriamente...");
    })
  }
}
