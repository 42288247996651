import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { Routes, RouterModule } from "@angular/router";
import { HttpClientModule,HTTP_INTERCEPTORS  } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './components/home/home.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { AuthInterceptorService } from './helpers/auth.interceptor';
import { ApscreenComponent } from './components/apscreen/apscreen.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './components/dialog/dialog.component';
import { TaxscreenComponent } from './components/taxscreen/taxscreen.component';
import { ApcapturaComponent } from './components/apcaptura/apcaptura.component';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RhscreenComponent } from './components/rhscreen/rhscreen.component';
import { RegistroComponent } from './components/registro/registro.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxCaptchaModule } from 'ngx-captcha';
import { AvisoComponent } from './components/aviso/aviso.component';
import { QuestionarioComponent } from './components/questionario/questionario.component';
import { VistacuestionComponent } from './components/vistacuestion/vistacuestion.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { MatTableModule } from '@angular/material/table' 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DownloadComponent } from './components/download/download.component'; 
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import { RhvalidacionComponent } from './components/rhvalidacion/rhvalidacion.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DocumentsComponent,
    ApscreenComponent,
    DialogComponent,
    TaxscreenComponent,
    ApcapturaComponent,
    RhscreenComponent,
    RegistroComponent,
    AvisoComponent,
    QuestionarioComponent,
    VistacuestionComponent,
    SuppliersComponent,
    DownloadComponent,
  
    // RhvalidacionComponent
    
  ],
  entryComponents:[
    DialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
     RouterModule,
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    DataTablesModule,
    CurrencyMaskModule,
    NgxCaptchaModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
