import { Component,OnDestroy, OnInit,AfterViewInit,ViewChild  } from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LockedComponent } from '../alerts/locked.component';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-taxscreen',
  templateUrl: './taxscreen.component.html',
  // styleUrls: ['./taxscreen.component.scss']
})
export class TaxscreenComponent implements OnDestroy,OnInit {
  closeResult = '';
  public valImms: number
  public valImpu: number
  public RFCProv: string
  public mesDoct: string
  public doctos:      Array<any> =[]
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public cmbPlanta:      Array<any> =[]
  dtInstance: DataTables.Api;
  @ViewChild(DataTableDirective)
  
  // dtOptions: any = {};
  myFormTax: FormGroup;
  rfcProvee:  Array<any> =[]
  rfcProve: string
  fechaIni: string
  fechaFin: string
  myFormPla: FormGroup;
  planta: string
  dtOptionstax: DataTables.Settings = {};

  isDtInitialized:boolean = false
  dtElement: DataTableDirective;
  constructor(
    private scvSuppl: SuppliersService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) { }
  open(content) {
    this.modalService.open(content, { size: 'sm' }); 
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.cmbPlantas()
    // this. doctoTax(); mike
    this.cmbSupplier();
    this.myFormTax = this.formBuilder.group({
      'fechaIni':new FormControl('',{validators:[Validators.required]}),
      'fechaFin':new FormControl('',{validators:[Validators.required]}),
      'rfcProve':new FormControl('',{validators:[Validators.required]})
    })
    this.myFormPla = this.formBuilder.group({
      'planta':new FormControl('',{validators:[Validators.required]}),
     
    })
    this.dtOptionstax = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 10
    };
  
  }
  // ngAfterViewInit(): void {
  //   this.dtTrigger.next();
  // }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  doctoTax(){
    if(this.myFormTax.invalid){
      alert("Todos los campos son requeridos.");
      return 0;
      }
    this.scvSuppl.taxView(this.rfcProve,this.fechaIni,this.fechaFin).subscribe((resp: any) =>{
      this.doctos = resp;
      console.log(this.doctos);
      if (resp){
    
      }
    })
  }
  taxDocIMSS(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.immsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })

  }
  taxDocCntr(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.cntrDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })

  }
  taxDocTaxs(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.taxsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfStps(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.stpsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfNomi(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.nomiDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfInfo(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.infoDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
validarTax(aniomes: string,rfc: string,content: any){
  this.mesDoct=aniomes;
  this.RFCProv=rfc;
  this.valImpu=1
  this.valImms=0
  this.open(content)

}
ValidarIms(aniomes: string,rfc: string,content: any){
  this.mesDoct=aniomes;
  this.RFCProv=rfc;
  this.valImpu=0
  this.valImms =1
  this.open(content)
}
onValidarTax(){
  this.scvSuppl.aceptarTax(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
    // alert("aceptado");
    this. doctoTax();
    this.modalService.dismissAll();
  })
}
onValidarIms(){ 
this.scvSuppl.aceptarImms(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
  // alert("aceptado");
  this. doctoTax();
  this.modalService.dismissAll();
})
}

cmbSupplier(){
  this.scvSuppl.cmbProvedores().subscribe((resp: any) =>{
    this.rfcProvee=resp
  })
}
excel(){
  if(this.myFormTax.invalid){
    alert("Todos los campos son requeridos.");
    return 0;
    }
   
  this.scvSuppl.exportXls(this.rfcProve,this.fechaIni,this.fechaFin).subscribe((resp:any)=>{
    let blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
      // window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
  })
}
excelSinDocto(){ 
  if(this.planta =="" || this.planta ==null || this.planta ==undefined){
    alert("Selecciona el campo planta");
    return 0;
  }
  this.abreLocked("Procesando...");
  this.scvSuppl.exportSinDocto(this.planta).subscribe((resp:any)=>{
    let blob = new Blob([resp], { type: 'application/vnd.ms-excel' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    this.closeLocked();
      // window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
  })
}
abreLocked(Msg: String){
  const dialogRef = this.dialog.open(LockedComponent,{ 
     disableClose: true,
     data:{
      Msg: Msg
     }
  
  });
  dialogRef.afterClosed().subscribe((result:any) => {
    // console.log(result);
  });
}
closeLocked(){
  const dialogRef = this.dialog.closeAll();
}
vistaimpuesto(){
  if(this.myFormTax.invalid){
    alert("Todos los campos son requeridos.");
    return 0;
    }
    this.abreLocked("Procesando...");
  this.scvSuppl.taxView(this.rfcProve,this.fechaIni,this.fechaFin).subscribe((resp: any) =>{
    // this.dtTrigger.unsubscribe();
    this.doctos=resp
    this.closeLocked();
    // this.dtTrigger.unsubscribe();
    // this.dtTrigger.next();
    if(this.isDtInitialized){
       this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //  this.dtTrigger.unsubscribe();
         dtInstance.destroy();
         this.datatable();
        this.dtTrigger.next();
         
     });
    }else {
      this.isDtInitialized = true
      this.dtTrigger.next();
    }
    
  
   
  })
}
datatable(){
 this.dtOptionstax = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 10
    };
}
cmbPlantas(){
  
   this.scvSuppl.cmbPlantas().subscribe((resp:any)=> {
     this.cmbPlanta=resp;
    
     })
 }

}
export class LanguageApp {
  public static spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }
}