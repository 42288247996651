
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>Sonoco | Log in</title>

  <!-- Google Font: Source Sans Pro -->
  <!-- <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback"> -->
  <!-- Font Awesome -->
  <!-- <link rel="stylesheet" href="node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css"> -->
  <!-- icheck bootstrap -->
  <!-- <link rel="stylesheet" href="node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"> -->
  <!-- Theme style -->
  <!-- <link rel="stylesheet" href="node_modules/admin-lte/dist/css/adminlte.min.css"> -->
  <script src="https://www.google.com/recaptcha/api.js" async defer></script>
</head>
<body class="hold-transition login-page">
<div class="login-box">
  <div class="login-logo">
    <a href=""><b>SONOCO</b>México</a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Iniciar Sesión</p>

      <!-- <form action="../../index3.html" method="post"> -->
      <form [formGroup]="aFormGroup" (ngSubmit)="onLogin()">
        <div class="input-group mb-3">
          <input type="email" class="form-control" 
          formControlName="email" 
          [(ngModel)]="email"
          placeholder="Correo">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="password" class="form-control" 
          formControlName="password" 
          [(ngModel)]="password"
          placeholder="Contraseña">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <br>
        <div class="row ">
          <!-- <div class="g-recaptcha" data-sitekey="6Lc93PAbAAAAALvfP0mkv_RRnulZVWI-H2ZYBsrf"></div> -->
          <!-- <div class="col-12 text-center">
            <ngx-recaptcha2 #captchaElem
            [siteKey]="siteKey"
          
            formControlName="recaptcha">
          </ngx-recaptcha2>
          </div> -->

      </div>
      <br>
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-info" (click)="Registrar(content2)">Registrarse</button>
           
          </div>
          <!-- /.col -->
          <div class="col-6 text-right">
            <button type="submit" class="btn btn-outline-primary">Iniciar</button>
          </div>
          
          <!-- /.col -->
        </div>
        <hr>
        <div class="row">
        
      
           <a class="text-center" href="https://sonoco.com.mx/repse/">Consulta nuestros videos de apoyo</a>
     
        </div>
       
      </form>
      <!-- <form [formGroup]="aFormGroup">
        
      </form> -->

     

      <!-- <div class="social-auth-links text-center mb-3">
        <p>- OR -</p>
        <a href="#" class="btn btn-block btn-primary">
          <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
        <a href="#" class="btn btn-block btn-danger">
          <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
        </a>
      </div> -->
      <!-- /.social-auth-links -->

      <!-- <p class="mb-1">
        <a href="forgot-password.html">I forgot my password</a>
      </p> -->
      <p class="mb-0">
        <!-- <a (click)="Registrar(content2)">Registro</a> -->
       
        <!-- <a href="register.html" class="text-center">Registro en desarrollo</a> -->
      </p>
    </div>
    <!-- /.login-card-body -->
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Registro al portal de proveedores de servicios especializados</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body">
    <form  [formGroup]="myFormRe" (ngSubmit)="registration()">
      <div class="input-group mb-3">
        <input type="text" class="form-control" 
        formControlName="name" 
        [(ngModel)]="name"
        placeholder="Razón Social">
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-user"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input type="text" 
        class="form-control"
        formControlName="rfc" 
        [(ngModel)]="rfc"
        placeholder="RFC">
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-user"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
          <input type="email" 
          class="form-control"
          formControlName="email"  
          [(ngModel)]="email"
          placeholder="Correo">
          <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
          </div>
      </div>
      <span *ngIf="this.myFormRe.get('email').errors?.email" class="badge badge-danger"> 
        Correo no valido
      </span>
      <div class="input-group mb-3">
        <input type="password"
         class="form-control"
         formControlName="password"
         [(ngModel)]="password" 
         placeholder="Contraseña">
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <!-- <div class="input-group mb-3">
        <input type="password" class="form-control" placeholder="Retype password">
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-6">
          <!-- <div class="icheck-primary">
            <input type="checkbox" id="agreeTerms" name="terms" value="agree">
            <label for="agreeTerms">
             I agree to the <a href="#">terms</a>
            </label>
          </div> -->
        </div>
        <!-- /.col -->
        <div class="col-6 text-right">
          <button type="submit" class="btn btn-outline-dark" (click)="modal.close('Save click')">Registrarse</button>
        </div>
        <!-- /.col -->
      </div>
    </form>

  </div>
  <!-- <div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="Turnar(); modal.close('Save click')">Registrar</button>
  </div> -->
</ng-template>

<!-- /.login-box -->
<!-- <router-outlet></router-outlet> -->
<!-- jQuery -->
<!-- <script src="node_modules/admin-lte/plugins/jquery/jquery.min.js"></script> -->
<!-- Bootstrap 4 -->
<!-- <script src="node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"></script> -->
<!-- AdminLTE App -->
<!-- <script src="node_modules/admin-lte/dist/js/adminlte.min.js"></script> -->
</body>
</html>

    <!-- <div class="row">
      <div class="col-5"></div>
      <div class="col-2">
          <div class="login-box ">
            <div class="login-logo">
              <a ><b>SONOCO</b>MX</a>
            </div>
     
            <div class="card">
              <div class="card-body login-card-body">
                <p class="login-box-msg">Iniciar Sesión</p>
          
                <form [formGroup]="formLogin" (ngSubmit)="onLogin()">

                  <div class="input-group mb-3">
                    <input type="email" class="form-control"  formControlName="email" placeholder="Email">
                      <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                          </div>
                      </div>
                  </div>

                  <div class="input-group mb-3">
                      <input type="password" class="form-control" formControlName="password" placeholder="Password">
                      <div class="input-group-append">
                          <div class="input-group-text">
                              <span class="fas fa-lock"></span>
                          </div>
                      </div>
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                         <div *ngIf="f.password.errors.required">Password is required</div>
                      </div>

                  </div>

                  <div class="row">
                  
                 
                    <div class="col-4">
                      <button type="submit" class="btn btn-info btn-block">login</button>
                    </div>
               
                  </div>
                </form>
          
                
          
                <p class="mb-1">
              
                </p>
                <p class="mb-0">
                  <a href="register.html" class="text-center">Registrarse</a>
                </p>
              </div>
           
            </div>
          </div>
      </div>
      <div class="col-5"></div>
    </div>
 -->
