import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuppliersService } from '../../services/suppliers.service';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  constructor(
     @Inject(MAT_DIALOG_DATA) private data: string,
     private matDialogRef: MatDialogRef<DialogComponent>,
     private scvSuppl: SuppliersService
  ) { }

  ngOnInit(): void {
  // this.enviarEmail();
  }
  ngOnDestroy(){
    this.matDialogRef.close(this.data);
  }
  onCloseClick(){
    this.matDialogRef.close();
  }
  enviarEmail(){
    this.scvSuppl.apEmail(this.data).subscribe((resp: any) =>{
  
      if (resp){
       this.matDialogRef.close();
      }
    })
    // this.matDialogRef.close();
   
  }
}
