<p>
    Para leer el aviso de privacidad y confidencialidad completo, selecciona la empresa para la cual prestes tus servicios
    
    </p>
    <form [formGroup]="myFormAp" (ngSubmit)="submitAviso()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                      <!-- <h3>Razón Social</h3> -->
                      <select 
                      
                            class="form-control select2 select2-danger" 
                            formControlName="razonSocial" 
                            data-dropdown-css-class="select2-danger" 
                            style="width: 100%;"
                            maxlength="200"
                            (change)="onchangeRazon($event)"
                            >
                            
                            <!-- <option value="" placeholder="Selecciona la planta">Empresa</option>  -->
                            <option value={{razon.planta}} *ngFor="let razon of cmbRazon"> {{razon.razonSocial}} </option>  
                      </select>
                </div>
            </div>
            <!-- <div class="col-md-6">
                <button  class="btn btn-primary" >Aceptar</button>
            </div> -->
        </div>
        <iframe width="100%" height="500" frameBorder="0" [src]="urlSafe"></iframe>
        <hr>
        <div class="row">
            <div class="col-md-10">
                <!-- <button  class="btn btn-primary" >Aceptar</button> -->
            </div>
            <div class="col-md-2">
                <button type="submit"  class="btn btn-primary" >Aceptar</button>
            </div>
        </div>
    </form>
