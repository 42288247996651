<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-circus" [fullScreen] = "true"><p style="color: white" > Guardando... </p>
</ngx-spinner>
<div class="card">
      <div class="card-header">
         <h3 class="card-title">Cuestionario anual</h3>
      </div>
      
      <div class="card-body p-0">
            <div class="row">
                <form [formGroup]="myQuestion" (ngSubmit)="SaveRespuesta()">
                    
                 
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr >
                                    <td>
                                        Planta   
                                       <label *ngIf="this.myQuestion.get('Planta').errors?.required" class="font-weight-bold text-danger">*</label>   
                                        <select                        
                                            formControlName="Planta" 
                                            class="form-control"
                                            >
                                            <!-- <option value="0" selected="selected" >Selecciona la planta</option>  -->
                                            <option value={{planta.planta}} *ngFor="let planta of plantaQue"> {{planta.planta}} </option>  
                                            
                                        </select>
                                    </td>
                                    <td>
                                       ¿El personal asignado realizó los trabajos con elementos propios del prestador de servicios?
                                        <!-- {{question1}}                      -->
                                        <!-- <h3><label *ngIf="this.myQuestion.get('factura').errors?.required" class="font-weight-bold text-danger">*</label></h3> -->
                                        <label *ngIf="this.myQuestion.get('QUno').errors?.required" class="font-weight-bold text-danger">*</label> 
                                        <select                        
                                            formControlName="QUno" 
                                            class="form-control"
                                            >
                                            <option value="0" selected >Selecciona la respuesta</option> 
                                        
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                      
                                        </select>
                                    </td>
                                    
                                </tr>
                              
                                <tr>
                                    <td>
                                      <!-- {{question2}} -->
                                      ¿El beneficiario es responsable de la dirección, supervisión o capacitación del personal asignado?  
                                      <label *ngIf="this.myQuestion.get('QDos').errors?.required" class="font-weight-bold text-danger">*</label>              
                                      <!-- <h3><label *ngIf="this.myQuestion.get('factura').errors?.required" class="font-weight-bold text-danger">*</label></h3> -->
                                      <select                        
                                          formControlName="QDos" 
                                          class="form-control"
                                          >
                                          <option value="0" selected >Selecciona la respuesta</option> 
                                      
                                          <option value="SI">SI</option>
                                          <option value="NO">NO</option>
                                    
                                      </select>
                                    </td>
                                    <td>
                                      <!-- {{question3}} -->
                                      No. de trabajadores              
                                      <label *ngIf="this.myQuestion.get('QTres').errors?.required" class="font-weight-bold text-danger">*</label>              
                                      <input type="text" 
                                      class="form-control"
                                      formControlName="QTres"                                       
                                      maxlength="45"> 
                                    </td>
                                    
                                </tr>
                                
                                <tr>
                                  <td>
                                    <!-- {{question4}} -->
                                    Actividad económica especializada manifestada en REPSE             
                                    <label *ngIf="this.myQuestion.get('QCuatro').errors?.required" class="font-weight-bold text-danger">*</label>              
                                    <input type="text" 
                                    class="form-control"
                                    formControlName="QCuatro"                                     
                                    maxlength="250">                    
                                  </td>
                                  <td>
                                    <!-- {{question5}} -->
                                    Folio SIPRESS             
                                    <label *ngIf="this.myQuestion.get('QCinco').errors?.required" class="font-weight-bold text-danger">*</label>              
                                    <input type="text" 
                                    class="form-control"
                                    formControlName="QCinco"                               
                                    maxlength="250">                  
                                  </td>
                                  
                                </tr>
                               
                                <tr>
                                  <td>
                                    <!-- {{question6}} -->
                                    Folio del contrato reportado en ICSOE                 
                                    <label *ngIf="this.myQuestion.get('QSeis').errors?.required" class="font-weight-bold text-danger">*</label>              
                                    <input type="text" 
                                    class="form-control"
                                    formControlName="QSeis"                                    
                                    maxlength="250">
                                  </td>
                                  <td>
                                    <!-- {{question7}} -->                                 
                                    Costo anual de nómina            
                                    <label *ngIf="this.myQuestion.get('QSiete').errors?.required" class="font-weight-bold text-danger">*</label>              
                                    <input type="text" 
                                    class="form-control"
                                    formControlName="QSiete"    
                                    maxlength="250">
                                  </td>
                                  <td>
                                    <button type="submit" class="btn btn-primary" >Enviar</button>
                                  </td>
                                  
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                    
               
            </div>
      </div>
      
</div>