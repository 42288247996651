import { Component,Inject,OnDestroy, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SuppliersService } from '../../services/suppliers.service';
import {environment} from 'src/environments/environment.prod';
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from 'rxjs';
import {catchError,map} from 'rxjs/operators';
import { LockedComponent } from '../alerts/locked.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnDestroy,OnInit {
  public invoices:      Array<any> =[]
  public facturas:      Array<any> =[]
  dtSuppliers: DataTables.Settings = {};
  dtTriggerSu: Subject<any> = new Subject<any>();
  factura: number;
  myForm: FormGroup; 
  myFormTax: FormGroup; 
  myFormInf: FormGroup; 
  myFormStp: FormGroup;
  myFormNom: FormGroup;
  myFormCntr: FormGroup;
  mes: string;
  docTax: number;
  docIms: number;
  docInf: number;
  docNom: number;
  docStp: number;
  docCntr:number;
  plant:  string;
  rfcReceptor:  string;
 public  userRfc: string


  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private scvSuppl: SuppliersService,
    public dialog: MatDialog,
  ) {
    
    if (localStorage.getItem("rfc") === null) {
      // alert(localStorage.getItem("rfcs"));
      setTimeout(() => {
        this.userRfc=localStorage.getItem("rfc")
        this.apInvoices();
        this.suDocto(); 
      }, 3000)
    }else{
      this.userRfc=localStorage.getItem("rfc")
      this.apInvoices();
      this.suDocto();
    }
     
   }

  ngOnInit(): void {
    setTimeout(() => {
      this.spinner.hide();
    }, 50000);

    this.dtSuppliers = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 15
    };

    this.myForm = this.formBuilder.group({
      profile: [''],
      'file':new FormControl('',{validators:[Validators.required]})
      
    });

    this.myFormTax = this.formBuilder.group({
      'file':new FormControl('',{validators:[Validators.required]}),
      profile: ['']
    });
    this.myFormNom = this.formBuilder.group({
      'file':new FormControl('',{validators:[Validators.required]}),
      profile: ['']
    });
    this.myFormInf = this.formBuilder.group({
      'file':new FormControl('',{validators:[Validators.required]}),
      profile: ['']
    });
    this.myFormStp = this.formBuilder.group({
      'file':new FormControl('',{validators:[Validators.required]}),
      profile: ['']
    });
    this.myFormCntr = this.formBuilder.group({
      'file':new FormControl('',{validators:[Validators.required]}),
      profile: ['']
    });
  //  this.apInvoices();
  //  this.suDocto();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerSu.unsubscribe();
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.get('profile').setValue(file);
    }
  }
  onFileChangeTax(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myFormTax.get('profile').setValue(file);
    }
  }
  onFileChangeNom(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myFormNom.get('profile').setValue(file);
    }
  }
  onFileChangeCntr(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myFormCntr.get('profile').setValue(file);
    }
  }
  onFileChangeStps(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myFormStp.get('profile').setValue(file);
    }
  }
  onFileChangeInfona(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myFormInf.get('profile').setValue(file);
    }
  }
 
  submit(): void{
    if (this.mes==undefined){
      alert("Selecciona el mes");
      return 
    }
    if(this.myForm.invalid){
      alert("Selecciona el archivo pdf");
      return ;
      }
    // this.spinner.show();
    this.abreLocked("Procesando...");
    let formData = new FormData();
    // const userRfc = localStorage.getItem('rfc');
    let aniomes = this.mes;
    //  alert(aniomes);
    formData.append('file', this.myForm.get('profile').value);
    formData.append('rfc', this.userRfc);
    formData.append('mes', this.mes);
    formData.append('planta', this.plant);
    formData.append('rfcReceptor', this.rfcReceptor);
    this.http.post<any>(`${environment.API_URL}/uploadFile`, formData
    )
      .subscribe(res => {
        // this.spinner.hide();
        this.closeLocked();
        console.log(res);
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        // alert('Subió Satisfactoriamente.');
        for (const mg in res){
          alert(res[mg]);
        }
      })
  }
  submittax(): void{
    if (this.mes==undefined){
      alert("Selecciona el mes");
      return 
    }
    if(this.myFormTax.invalid){
      alert("Selecciona el archivo pdf");
      return ;
      }
    // this.spinner.show();
    this.abreLocked("Procesando...");
    let formData = new FormData();
    // const userRfc = localStorage.getItem('rfc');
     formData.append('file', this.myFormTax.get('profile').value);
     formData.append('rfc',this.userRfc);
     formData.append('mes', this.mes);
     formData.append('planta', this.plant);
     formData.append('rfcReceptor', this.rfcReceptor);
    this.http.post<any>(`${environment.API_URL}/uploadFileTax`, formData
    )
      .subscribe(res => {
        this.closeLocked();
        // this.spinner.hide();
        // console.log(res);
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        // alert('Subió Satisfactoriamente.');
        for (const mg in res){
          alert(res[mg]);
        }
      })
  }
  submitnomina(): void{
    if (this.mes==undefined){
      alert("Selecciona el mes");
      return 
    }
    if(this.myFormNom.invalid){
      alert("Selecciona el archivo pdf");
      return ;
      }
    // this.spinner.show();
    this.abreLocked("Procesando...");
    let formData = new FormData();
    // const userRfc = localStorage.getItem('rfc');
    formData.append('file', this.myFormNom.get('profile').value);
     formData.append('rfc', this.userRfc);
     formData.append('mes', this.mes);
     formData.append('planta', this.plant);
     formData.append('rfcReceptor', this.rfcReceptor);
    this.http.post<any>(`${environment.API_URL}/uploadNomina`, formData
    )
      .subscribe(res => {
        this.closeLocked();
        // this.spinner.hide();
        // console.log(res);
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        // alert('Subió Satisfactoriamente.');
        for (const mg in res){
          alert(res[mg]);
        }
      })
  }
  submitstps(): void{
    if (this.mes==undefined){
      alert("Selecciona el mes");
      return 
    }
    if(this.myFormStp.invalid){
      alert("Selecciona el archivo pdf");
      return ;
      }
    // this.spinner.show();
    this.abreLocked("Procesando...");
    let formData = new FormData();
    // const userRfc = localStorage.getItem('rfc');
    formData.append('file', this.myFormStp.get('profile').value);
     formData.append('rfc', this.userRfc);
     formData.append('mes', this.mes);
     formData.append('planta', this.plant);
     formData.append('rfcReceptor', this.rfcReceptor);
    this.http.post<any>(`${environment.API_URL}/uploadStps`, formData
    )
      .subscribe(res => {
        this.closeLocked();
        // this.spinner.hide();
        // console.log(res);
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        for (const mg in res){
          alert(res[mg]);
        }
        // alert('Subió Satisfactoriamente.');
      })
  }
  submitinfona(): void{
    if (this.mes==undefined){
      alert("Selecciona el mes");
      return 
    }
    if(this.myFormInf.invalid){
      alert("Selecciona el archivo pdf");
      return ;
      }
      this.abreLocked("Procesando...");
    // this.spinner.show();
    let formData = new FormData();
    // const userRfc = localStorage.getItem('rfc');
     formData.append('file', this.myFormInf.get('profile').value);
     formData.append('rfc', this.userRfc);
     formData.append('mes', this.mes);
     formData.append('planta', this.plant);
     formData.append('rfcReceptor', this.rfcReceptor);
    this.http.post<any>(`${environment.API_URL}/uploadInfonavit`, formData
    )
      .subscribe(res => {
        this.closeLocked();

        // this.spinner.hide();
        // console.log(res);
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        for (const mg in res){
          alert(res[mg]);
        }
      
      })
  }
  submitcontr(){
      if (this.mes==undefined){
        alert("Selecciona el mes");
        return 
      }
      if(this.myFormCntr.invalid){
        alert("Selecciona el archivo pdf");
        return ;
      }
      this.abreLocked("Procesando...");
      // this.spinner.show();
      let formData = new FormData();
      
      formData.append('file', this.myFormCntr.get('profile').value);
      formData.append('rfc', this.userRfc);
      formData.append('mes', this.mes);
      formData.append('planta', this.plant);
      formData.append('rfcReceptor', this.rfcReceptor);
      this.http.post<any>(`${environment.API_URL}/uploadFileContrato`, formData
    )
      .subscribe(res => {
        // this.spinner.hide();
        this.closeLocked();
        this.apInvoices();
        this.onChangeCmb("NO");
        this. ngOnDestroy();
        this.suDocto();
        for (const mg in res){
          alert(res[mg]);
        }
      
      })

  }


  apInvoices(){
    this.scvSuppl.combo(this.userRfc).subscribe((resp: any) =>{
      this.invoices = resp;
      if (resp){
        if(resp.length==0){
          this.factura=0
        }else{
          this.factura=1
          if(resp.status=='Token is Invalid'){
            this.factura=3
          }else{
            
          }
        }       
        console.log(resp);
      }else{
        this.factura=0
      }
    })
  }
  suDocto(){
    // const userRfc = localStorage.getItem('rfc');
    this.scvSuppl.SupDocto(this.userRfc).subscribe((resp: any)=>{
    this.facturas=resp
    this.dtTriggerSu.next();
    })
  }
  // onChangetext(event) {
  //   var str=event.target.options[event.target.options.selectedIndex].text;
  //   var spling=str.split(":");
  //   this.plant=spling[1];
  //   // console.log( this.plant);
  // }
  spling:string[];
  onChangeCmb(event): void{
    if (event != "NO"){
      var str=event.target.options[event.target.options.selectedIndex].text;
      // this.spling=str.split(":");
      // this.plant=this.spling[1];
      for (let variable in this.invoices) {    
        // alert(this.cmbllena[variable].name);     
        if (this.invoices[variable].meses === str) {
          // checkboxsensores.push(variable);
          // alert(this.cmbllena[variable].rfc);
       
          this.plant=this.invoices[variable].planta;
          this.rfcReceptor=this.invoices[variable].rfcReceptor;
          // this.scvSuppl.selectPlanta(id).subscribe((resp:any)=>{
          //   this.plantas=resp;
          // })
        }
     }

    }
    var imss;
    var taxs;
    var info;
    var nomi;
    var stps;
    var cntr;

    // const userRfc = localStorage.getItem('rfc');
    this.scvSuppl.pintaCmb(this.userRfc,this.mes, this.plant,this.rfcReceptor).subscribe((resp:any)=>{ 
       resp.forEach(function(arrayItem){
        // console.log("hola"+arrayItem.mesTax);
        taxs=arrayItem.mesTax
        imss=arrayItem.mesIms
        info=arrayItem.mesInf
        nomi=arrayItem.mesNom
        stps=arrayItem.mesStp
        cntr=arrayItem.mesCon
      })   
      if(imss=='0'){
        this.docIms=0;
      }else{
        this.docIms=1;
      }
      if(taxs=='0'){
        this.docTax=0;
      }else{
        this.docTax=1;
      }

      if(info=='0'){
        this.docInf=0;
      }else{
        this.docInf=1;
      }

      if(nomi=='0'){
        this.docNom=0;
      }else{
        this.docNom=1;
      }

      if(stps=='0'){
        this.docStp=0;
      }else{
        this.docStp=1;
      }

      if(cntr=='0'){
        this.docCntr=0;
      }else{
        this.docCntr=1;
      }
    })
  }
  abreLocked(Msg: String){
    const dialogRef = this.dialog.open(LockedComponent,{ 
       disableClose: true,
       data:{
        Msg: Msg
       }
    
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log(result);
    });
  }
  closeLocked(){
    const dialogRef = this.dialog.closeAll();
  }


}
export class LanguageApp {
  public static spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }
}