<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header border-transparent">
                <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-6"><h1 class="card-title">Solicitudes sin documentos</h1></div>
                      <div class="col-md-3"></div>
                </div>
          </div> 
        </div>
    </div> 
</div>