
<div class="card">
  <div class="card-header border-transparent">
    <div class="row">
      <div class="col-md-3">

      </div>
      <div class="col-md-6">  
        <h1 class="card-title">Documentos pendientes por validar</h1>
      </div>
      <div class="col-md-3"></div>
  </div>
  <div class="row">
    <!-- <form [formGroup]="myFormPla" (ngSubmit)="submitcaptura()"> -->
      <div class="col-md-3">
        <div class="form-group">
            
            <!-- <h3>Planta -->
              <!-- <label *ngIf="this.myFormAp.get('razonSocial').errors?.required" class="font-weight-bold text-danger">*</label> -->
            <!-- </h3>         -->
            <select 
            class="form-control select2 select2-danger" 
            formControlName="razonSocial" 
            data-dropdown-css-class="select2-danger" 
            style="width: 100%;"
            maxlength="200"
            (change)="doctoTax($event.target.value)"
            >
            <option value="" placeholder="Selecciona la planta">Selecciona la planta</option>  
            <option value={{planta.Planta}} *ngFor="let planta of cmbPlanta"> {{planta.Planta }} </option>  
            </select>
          
        </div>
      </div>
      <!-- <div class="col-md-3">
        <button type="submit" class="btn btn-primary" >Buscar</button>
      </div> -->

    <!-- </form> -->
    
  </div>
  
  
  </div> 

 
  <div class="card-body p-0">
    <mat-form-field>
      <mat-label><h3>FILTROS</h3></mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filtra cual quier columna" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-striped table-hover">
    
        <!-- Position Column -->
        <ng-container matColumnDef="rfc">
          <th mat-header-cell *matHeaderCellDef> RFC </th>
          <td mat-cell *matCellDef="let element"> {{element.rfc}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Razón social</th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
    
        <!-- Weight Column -->
        <ng-container matColumnDef="planta">
          <th mat-header-cell *matHeaderCellDef>Planta</th>
          <td mat-cell *matCellDef="let element"> {{element.planta}} </td>
        </ng-container>
    
        <!-- Symbol Column -->
        <ng-container matColumnDef="rfcReceptor">
          <th mat-header-cell *matHeaderCellDef>RFC-Receptor</th>
          <td mat-cell *matCellDef="let element"> {{element.rfcReceptor}} </td>
        </ng-container>
        <ng-container matColumnDef="aniomes">
          <th mat-header-cell *matHeaderCellDef>Mes</th>
          <td mat-cell *matCellDef="let element">{{element.aniomes}}</td>
        </ng-container>
        <ng-container matColumnDef="valcntr"> 
          <th mat-header-cell *matHeaderCellDef>PDF-CONTRATO</th>
          <td mat-cell *matCellDef="let element">
              <button *ngIf="element.valcntr == 0 || element.valcntr ==1 " type="button" class="btn btn-danger fas fa-file-pdf" (click)="downDocCntr(element.aniomes,element.rfc,element.idcntr)"></button>
              <div *ngIf="element.valcntr ==0" class="form-check">
                <input class="form-check-input" type="checkbox" (click)="ValidaCntr(element.aniomes,element.rfc,content,element.idcntr)">
                <label class="form-check-label">Validar</label>
              </div>
                <br>
              <label *ngIf="element.valcntr == 1" class="form-check-label badge badge-success">Aceptado</label>
              <label *ngIf="element.valcntr == 2" class="form-check-label badge badge-danger">Rechazado</label>
              
              <label *ngIf="element.valcntr == null" class="form-check-label badge badge-warning">Sin docto</label>
          </td>
        </ng-container>
        <ng-container matColumnDef="valimss">
          <th mat-header-cell *matHeaderCellDef>PDF-IMSS</th>
          <td mat-cell *matCellDef="let element">
              <button  *ngIf="element.valimss == 0 || element.valimss ==1 " type="button" class="btn btn-danger fas fa-file-pdf" (click)="taxDocIMSS(element.aniomes,element.rfc,element.idimss)"></button>
              <div *ngIf="element.valimss ==0" class="form-check">
                <input class="form-check-input" type="checkbox" (click)="ValidarIms(element.aniomes,element.rfc,content,element.idimss)">
                <label class="form-check-label">Validar</label>
              </div>
              <br>
              <label *ngIf="element.valimss == 1" class="form-check-label badge badge-success">Aceptado</label>
              <label *ngIf="element.valimss == 2" class="form-check-label badge badge-danger">Rechazado</label>
              <label *ngIf="element.valimss == null" class="form-check-label badge badge-warning">Sin docto</label>
          </td>
        </ng-container>
        

        <ng-container matColumnDef="valimpu">
          <th mat-header-cell *matHeaderCellDef>PDF-Impuestos</th>
          <td mat-cell *matCellDef="let element">
            <button    *ngIf="element.valimpu == 0 || element.valimpu ==1 " type="button" class="btn btn-danger fas fa-file-pdf" (click)="taxDocTaxs(element.aniomes,element.rfc,element.idimpu)"></button>
            <div *ngIf="element.valimpu ==0" class="form-check">
              <input class="form-check-input" type="checkbox" (click)="validarTax(element.aniomes,element.rfc,content,element.idimpu)">
              <label class="form-check-label">Validar</label>
            </div>
            <br>
            <label *ngIf="element.valimpu ==1" class="form-check-label badge badge-success">Aceptado</label>
            <label *ngIf="element.valimpu == 2" class="form-check-label badge badge-danger">Rechazado</label>
            <label *ngIf="element.valimpu == null" class="form-check-label badge badge-warning">Sin docto</label>

          </td>
        </ng-container>

        <ng-container matColumnDef="valstps">
          <th mat-header-cell *matHeaderCellDef>PDF-STPS</th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.valstps == 0 || element.valstps ==1 " type="button" class="btn btn-danger fas fa-file-pdf" (click)="downPdfStps(element.aniomes,element.rfc,element.idstps)"></button>
            <div *ngIf="element.valstps ==0" class="form-check">
              <input class="form-check-input" type="checkbox" (click)="ValidaStps(element.aniomes,element.rfc,content,element.idstps)">
              <label class="form-check-label">Validar</label>
            </div>
            <br>
            <label *ngIf="element.valstps == 1" class="form-check-label badge badge-success">Aceptado</label>
            <label *ngIf="element.valstps == 2" class="form-check-label badge badge-danger">Rechazado</label>
            <label *ngIf="element.valstps == null" class="form-check-label badge badge-warning">Sin docto</label>
          </td>
        </ng-container>

        <ng-container matColumnDef="valinfo">
          <th mat-header-cell *matHeaderCellDef>PDF-IFONAVIT</th>
          <td mat-cell *matCellDef="let element">
              <button *ngIf="element.valinfo == 0 || element.valinfo ==1 "  type="button" class="btn btn-danger fas fa-file-pdf" (click)="downPdfInfo(element.aniomes,element.rfc,element.idinfo)"></button>
              <div *ngIf="element.valinfo == 0" class="form-check">
                <input class="form-check-input" type="checkbox" (click)="ValidaInfo(element.aniomes,element.rfc,content,element.idinfo)">
                <label class="form-check-label">Validar</label>
              </div>
              <br>
              <label *ngIf="element.valinfo == 1" class="form-check-label badge badge-success">Aceptado</label>
              <label *ngIf="element.valinfo == 2" class="form-check-label badge badge-danger">Rechazado</label>
              <label *ngIf="element.valinfo == null" class="form-check-label badge badge-warning">Sin docto</label>

          </td>
        </ng-container>
        <ng-container matColumnDef="valnomi">
          <th mat-header-cell *matHeaderCellDef>PDF-NOMINA</th>
          <td mat-cell *matCellDef="let element">
            <button    *ngIf="element.valnomi == 0 || element.valnomi == 1 "  type="button" class="btn btn-danger fas fa-file-pdf" (click)="downPdfNomi(element.aniomes,element.rfc,element.idnomi)"></button>
            <div *ngIf="element.valnomi == 0" class="form-check">
              <input class="form-check-input" type="checkbox" (click)="ValidaNomi(element.aniomes,element.rfc,content,element.idnomi)">
              <label class="form-check-label">Validar</label>
            </div>
            <br>
            <label *ngIf="element.valnomi == 1" class="form-check-label badge badge-success">Aceptado</label>
            <label *ngIf="element.valnomi == 2" class="form-check-label badge badge-danger">Rechazado</label>
            <label *ngIf="element.valnomi == null" class="form-check-label badge badge-warning">Sin docto</label>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 *ngIf="valCntr==1" class="modal-title" id="modal-basic-title">Validación documento Contrato</h4>
    <h4 *ngIf="valImpu==1" class="modal-title" id="modal-basic-title">Validación documento Impuestos</h4>
    <h4 *ngIf="valImms==1" class="modal-title" id="modal-basic-title">Validación documento IMSS</h4>
    <h4 *ngIf="valInfo==1" class="modal-title" id="modal-basic-title">Validación documento INFONAVIT</h4>
    <h4 *ngIf="valNomi==1" class="modal-title" id="modal-basic-title">Validación documento Nomina</h4>
    <h4 *ngIf="valStps==1" class="modal-title" id="modal-basic-title">Validación documento STPS</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">¿Estas seguro de aceptar el documento?</label>
        <div class="input-group">

        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" *ngIf="valCntr==1" class="btn btn-outline-danger" (click)="onRejectAll('cntr')">Rechazar</button>
    <button type="button" *ngIf="valImpu==1" class="btn btn-outline-danger" (click)="onRejectAll('impu')">Rechazar</button>
    <button type="button" *ngIf="valImms==1" class="btn btn-outline-danger" (click)="onRejectAll('imms')">Rechazar</button>
    <button type="button" *ngIf="valInfo==1" class="btn btn-outline-danger" (click)="onRejectAll('info')">Rechazar</button>
    <button type="button" *ngIf="valNomi==1" class="btn btn-outline-danger" (click)="onRejectAll('nomi')">Rechazar</button>
    <button type="button" *ngIf="valStps==1" class="btn btn-outline-danger" (click)="onRejectAll('stps')">Rechazar</button>

    <button type="button" *ngIf="valCntr==1" class="btn btn-outline-success" (click)="onValidarCntr()">Aceptar</button>
    <button type="button"  *ngIf="valImpu==1" class="btn btn-outline-success" (click)="onValidarTax()">Aceptar</button>
    <button type="button" *ngIf="valImms==1" class="btn btn-outline-success" (click)="onValidarIms()">Aceptar</button>
    <button type="button" *ngIf="valInfo==1" class="btn btn-outline-success" (click)="onValidarInfo()">Aceptar</button>
    <button type="button" *ngIf="valNomi==1" class="btn btn-outline-success" (click)="onValidarNomi()">Aceptar</button>
    <button type="button" *ngIf="valStps==1" class="btn btn-outline-success" (click)="onValidarStps()">Aceptar</button>
    <button type="button"  class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
  </div>
</ng-template>
