import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor() {}
// Implementaciòn del metodo
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let authReq =req;
      const token=localStorage.getItem('token');
      const reqWithAuth= req.clone(
        {
          setHeaders:{
            Authorization:`Bearer ${token}`
          }
        });
        return next.handle(reqWithAuth).pipe(
          // retry(2),
          // catchError((error: HttpErrorResponse)=>{
          //   alert(`HTTP Error: ${req.url}`);
          //   return throwError(error);
            
          // })
        );    
  }

}