import { Component, OnInit,AfterViewInit, ViewChild} from '@angular/core';
import { SuppliersService } from '../../services/suppliers.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { isLiteralTypeNode } from 'typescript';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { empty, Subject } from 'rxjs';
import { LockedComponent } from '../alerts/locked.component';
import { MatDialog,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';
@Component({
  selector: 'app-rhscreen',
  templateUrl: './rhscreen.component.html',
  styleUrls: ['./rhscreen.component.scss']
})
export class RhscreenComponent implements AfterViewInit  {
 
  dataSource;
  ngAfterViewInit() {
    // this. doctoTax();
    // this.dataSource.paginator = this.paginator;
  }
  myFormPla: FormGroup;
  public cmbPlanta:      Array<any> =[]
  dtDoctosNoValidados: DataTables.Settings = {};
  dtTriggerNoValidados: Subject<any> = new Subject<any>();
  closeResult = '';
  public valImms: number
  public valImpu: number
  public valStps: number
  public valNomi: number
  public valInfo: number
  public valCntr: number

  public idImms: number
  public idImpu: number
  public idStps: number
  public idNomi: number
  public idInfo: number
  public idCntr: number

  public IdDoct: number

  public RFCProv: string
  public mesDoct: string
  public id: number
  public doctos:      Array<any> =[]
  public plants: string

   displayedColumns: string[] = ['rfc', 'nombre', 'planta', 'rfcReceptor','aniomes'
  ,'valcntr','valimss','valimpu','valstps','valinfo','valnomi'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
   
 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  

  constructor(
    private scvSuppl: SuppliersService,
    private modalService: NgbModal,
    public dialog: MatDialog,
  ) { }
  open(content) {
    this.modalService.open(content, { size: 'sm' }); 
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerNoValidados.unsubscribe();
  }
  ngOnInit(): void {
    this.cmbPlantas();
    // this. doctoTax();
   
    this.dtDoctosNoValidados = {
      language: LanguageApp.spanish_datatables,
      pagingType: 'full_numbers',
      pageLength: 10
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  doctoTax(val){
    
    console.log("planta:",val);
    if(val ==null || val ==undefined || val == ""){
      console.log("empty:",val);
      return 0;
    }
    this.abreLocked("Procesando...");
    this.plants = val;
    this.scvSuppl.taxDoctoByPlanta(val).subscribe((resp: any) =>{
      this.closeLocked();
      this.doctos = resp;
      this.dataSource = new MatTableDataSource(resp);
      this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
      // this.dtTriggerNoValidados.next();
      // console.log(this.dataSource);
      // if (resp){
       
      // }
    })
  }
  downDocCntr(aniomes: string,rfc: string, id:number){
    this.scvSuppl.cntrDocto(aniomes,rfc,id).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  taxDocIMSS(aniomes: string,rfc: string,ids:number){
    this.scvSuppl.immsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  taxDocTaxs(aniomes: string,rfc: string,ids:number){
    this.scvSuppl.taxsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfStps(aniomes: string,rfc: string,ids:number){
    this.scvSuppl.stpsDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfNomi(aniomes: string,rfc: string,ids:number){
    this.scvSuppl.nomiDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  downPdfInfo(aniomes: string,rfc: string,ids: number){
    this.scvSuppl.infoDocto(aniomes,rfc,ids).subscribe((response:any)=>{
      let blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
    })
  }
  validarTax(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=1
    this.valImms=0
    this.valInfo=0
    this.valNomi=0
    this.valStps=0
    this.valCntr=0
    this.open(content)
    this.IdDoct=IdDoct

  }
  ValidarIms(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=0
    this.valImms=1
    this.valInfo=0
    this.valNomi=0
    this.valStps=0
    this.valCntr=0
    this.open(content)
    this.IdDoct=IdDoct
  }
  ValidaInfo(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=0
    this.valImms=0
    this.valInfo=1
    this.valNomi=0
    this.valStps=0
    this.valCntr=0
    this.open(content)
    this.IdDoct=IdDoct
  }
  ValidaNomi(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=0
    this.valImms=0
    this.valInfo=0
    this.valNomi=1
    this.valStps=0
    this.valCntr=0
    this.open(content)
    this.IdDoct=IdDoct
  }
  ValidaStps(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=0
    this.valImms=0
    this.valInfo=0
    this.valNomi=0
    this.valStps=1
    this.valCntr=0
    this.open(content)
    this.IdDoct=IdDoct
  }
  ValidaCntr(aniomes: string,rfc: string,content: any, IdDoct: number){
    this.mesDoct=aniomes;
    this.RFCProv=rfc;
    this.valImpu=0
    this.valImms=0
    this.valInfo=0
    this.valNomi=0
    this.valStps=0
    this.valCntr=1
    this.open(content)
    this.IdDoct=IdDoct
  }
  onValidarTax(){
    this.scvSuppl.aceptarTax(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  onValidarIms(){

  this.scvSuppl.aceptarImms(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
    // alert("aceptado");
    this. doctoTax(this.plants);
    this.modalService.dismissAll();
  })
  }
  onValidarInfo(){
    this.scvSuppl.aceptarInfo(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  onValidarNomi(){
    this.scvSuppl.aceptarNomi(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  onValidarStps(){
    this.scvSuppl.aceptarStps(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  onValidarCntr(){

    this.scvSuppl.aceptarCntr(this.RFCProv,this.mesDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  onRejectAll(tab: string){
    // alert(this.IdDoct)
    // alert(tab)
    this.scvSuppl.rejectDocto(this.RFCProv,this.mesDoct,tab,this.IdDoct).subscribe((resp:any)=>{
      // alert("aceptado");
      this. doctoTax(this.plants);
      this.modalService.dismissAll();
    })
  }
  abreLocked(Msg: String){
    const dialogRef = this.dialog.open(LockedComponent,{ 
       disableClose: true,
       data:{
        Msg: Msg
       }
    
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      // console.log(result);
    });
  }
  closeLocked(){
    const dialogRef = this.dialog.closeAll();
  }
  cmbPlantas(){
    this.id = Number(localStorage.getItem('id'))
     this.scvSuppl.cmbPlanta(this.id).subscribe((resp:any)=> {
       this.cmbPlanta=resp;
      
       })
   }

}
export class LanguageApp {
  public static spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }
}


