import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {environment} from 'src/environments/environment.prod';
// import {InvoicesResponce} from '../interfaces/invoices.interface';
import { InvoicesResponce } from '../Interfaces/invoices.interface';
import {catchError,map} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(
    private http: HttpClient
  ) { }
apFacturas():Observable<InvoicesResponce  | void>{
  return this.http
     .post<InvoicesResponce>(`${environment.API_URL}/apListado`,"")
     .pipe(map((res:InvoicesResponce)=>{
        return res;
      } ),
      catchError((err)=> this.handleError(err))
    );
}
private handleError(err):Observable<never>{
  let errorMessage='An error occured retrieving data';
  if (err){
    errorMessage=`Error: code ${err.message}`;
    window.alert(errorMessage);
    return throwError(errorMessage);
  
  }
}
apEmail(uuid:string){
  return this.http.post(`${environment.API_URL}/apSendEmails`,JSON.stringify({
    uuid: uuid}))
  .pipe(map((res:InvoicesResponce)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
combo(rfc:string){
  return this.http.post(`${environment.API_URL}/llenaCombo`,JSON.stringify({
    rfc: rfc}))
   .pipe(
    map((res:InvoicesResponce)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
cmbProvedores(){
  return this.http.post(`${environment.API_URL}/cmbProveedor`,"")
   .pipe(
    map((res:InvoicesResponce)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
cmbProvedorDown(){
  return this.http.post(`${environment.API_URL}/cmbProveedorDown`,"")
   .pipe(
    map((res:InvoicesResponce)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
taxDocto(id: number){
  return this.http
     .post(`${environment.API_URL}/taxValidar`,JSON.stringify({
      id: id}))
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
immsDocto(aniomes: string, rfc: string,id:number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadImms`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
cntrDocto(aniomes: string, rfc: string,id:number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadCntr`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
taxsDocto(aniomes: string, rfc: string,id: number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadTax`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
stpsDocto(aniomes: string, rfc: string,id: number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadStps`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
nomiDocto(aniomes: string, rfc: string,id:number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadNomi`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
infoDocto(aniomes: string, rfc: string, id: number){
  // alert(aniomes);
  return this.http
     .post(`${environment.API_URL}/downloadInfo`,JSON.stringify({
      aniomes: aniomes,
      rfc: rfc,
      id: id
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
apCaptura(razonSocial:string
  ,rfc:string
  ,factura: string
  ,fechaFactura: string
  ,total: string
  ,planta: string
  ,rfcReceptor:string
  ){
  return this.http.post(`${environment.API_URL}/apSendEmails`,JSON.stringify({
    razonSocial: razonSocial,
    rfc: rfc,
    factura: factura,
    fechaFactura: fechaFactura,
    total: total,
    planta: planta,
    rfcReceptor: rfcReceptor
  }))
  .pipe(map((res:InvoicesResponce)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
apCapturado(id: number){
  return this.http.post(`${environment.API_URL}/apCapturado`,JSON.stringify({
    id: id
  }))
  .pipe(map((res:any)=>{
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}
SupDocto(rfc: string){
  // alert(rfc);
    return this.http.post(`${environment.API_URL}/suppierDocto`,
    JSON.stringify({
      rfc: rfc})
    )
    .pipe(map((res:any)=>{
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
pintaCmb(rfc: string,mes: string, planta: string, rfcReceptor: string){
  console.log(planta);
  return this.http.post(`${environment.API_URL}/pintacmb`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    planta: planta,
    rfcReceptor: rfcReceptor
  })
  )
  .pipe(map((res:any)=>{
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}
aceptarImms(rfc: string,mes: string){
    const userid = localStorage.getItem('id');
    return this.http.post(`${environment.API_URL}/aceptarImms`,
    JSON.stringify({
      rfc: rfc,
      mes: mes,
      userid: userid
    })
    )
    .pipe(map((res:any)=>{
      if (res==1){
        alert("Aceptado satisfactoriamente");
      }else{
        alert("Aceptado satisfactoriamente");
      }
      
      return res;
    } ),
    catchError((err)=> this.handleError(err))
  );
}
aceptarCntr(rfc: string,mes: string){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/aceptarCntr`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Aceptado satisfactoriamente");
    }else{
      alert("Aceptado satisfactoriamente");
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}
aceptarTax(rfc: string,mes: string){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/aceptarTax`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Aceptado satisfactoriamente");
    }else{
      alert("Aceptado satisfactoriamente");
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}

aceptarInfo(rfc: string,mes: string){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/aceptarInfo`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Aceptado satisfactoriamente  ");
    }else{
      alert("Aceptado satisfactoriamente  ");
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}

aceptarNomi(rfc: string,mes: string){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/aceptarNomi`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Aceptado satisfactoriamente  " );
    }else{
      alert("Aceptado satisfactoriamente  " );
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}
aceptarStps(rfc: string,mes: string){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/aceptarStps`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Aceptado satisfactoriamente  " );
    }else{
      alert("Aceptado satisfactoriamente  " );
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}
rejectDocto(rfc: string,mes: string,tab: string,IdDoct: number){
  const userid = localStorage.getItem('id');
  return this.http.post(`${environment.API_URL}/rechazarFile`,
  JSON.stringify({
    rfc: rfc,
    mes: mes,
    userid: userid,
    tab: tab,
    IdDoct: IdDoct
  })
  )
  .pipe(map((res:any)=>{
    if (res==1){
      alert("Rechazado satisfactoriamente..." );
    }else{
      alert("Rechazado satisfactoriamente..." );
    }
    
    return res;
  } ),
  catchError((err)=> this.handleError(err))
);
}

exportXls(rfcProve:string,fechaIni: string,fechaFin: string){
  return this.http.post(`${environment.API_URL}/exportExcel`,
  JSON.stringify({
    rfcProve: rfcProve,
    fechaIni: fechaIni,
    fechaFin: fechaFin
  }),{responseType:'arraybuffer'}
  ).pipe(
    catchError((err)=> this.handleError(err))
  );
}
exportResp(Fini: string,Ffin: string){
  return this.http.post(`${environment.API_URL}/exportRespuest`,
  JSON.stringify({   
    Fini: Fini,
    Ffin: Ffin
  }),{responseType:'arraybuffer'}
  ).pipe(
    catchError((err)=> this.handleError(err))
  );
}
exportSinDocto(planta: string){
  return this.http.post(`${environment.API_URL}/sinDocumentos`,
  JSON.stringify({   
    planta: planta
  }),{responseType:'arraybuffer'}
  ).pipe(
    catchError((err)=> this.handleError(err))
  );
}
taxView(rfcProve:string,fechaIni: string,fechaFin: string){
  return this.http
     .post(`${environment.API_URL}/viewTax`, JSON.stringify({
      rfcProve: rfcProve,
      fechaIni: fechaIni,
      fechaFin: fechaFin
    })
    )
     .pipe(
      catchError((err)=> this.handleError(err))
    );
}
cmbProv(id: number){
  return this.http
     .post<InvoicesResponce>(`${environment.API_URL}/comboProv`,JSON.stringify({
      id: id      
    }))
     .pipe(map((res:InvoicesResponce)=>{
        return res;
      } ),
      catchError((err)=> this.handleError(err))
    );
}
inputRfc(id: number){
  return this.http
     .post<InvoicesResponce>(`${environment.API_URL}/inputRfc`,  JSON.stringify({
      id: id     
    }))
     .pipe(map((res:InvoicesResponce)=>{
        return res;
      } ),
      catchError((err)=> this.handleError(err))
    );
}
selectPlanta(id: number){
  return this.http
     .post(`${environment.API_URL}/planta`,  JSON.stringify({
            id: id  
            })
         )
    .pipe(
      catchError((err)=> this.handleError(err))
    );
}
selectRazonSoc(user: string,rfc: string ){
     return this.http
           .post(`${environment.API_URL}/avisoPrivSelect`,JSON.stringify({
               user: user,
               rfc: rfc 
              }))
              .pipe(
                catchError((err)=> this.handleError(err))
              );
}
  insertAviso(rfc: string,usuario: string,nombre: string,razon: string,planta: string ){
    return this.http
    .post(`${environment.API_URL}/avisoPrivInsert`,JSON.stringify({
      usuario: usuario,
      nombre: nombre,
      rfc: rfc,
      razon: razon,
      planta: planta
      }))
      .pipe(
        catchError((err)=> this.handleError(err))
      );
  }
  plantaque(user: string,userRfc: string){
    return this.http
    .post(`${environment.API_URL}/plantaquestion`,JSON.stringify({
        user: user,
        userRfc: userRfc 
       }))
       .pipe(
         catchError((err)=> this.handleError(err))
       );
  }
  preguntas(){
    return this.http.post(`${environment.API_URL}/question`,"")
    .pipe(
      map((res:InvoicesResponce)=>{
        return res;
      } ),
      catchError((err)=> this.handleError(err))
    );
  }
  selectQuestion(Fini: string, Ffin: string){
    return this.http.post(`${environment.API_URL}/selectCuestion`,JSON.stringify({
      Fini: Fini,
      Ffin: Ffin 
     }))
    .pipe(
      // map((res:InvoicesResponce)=>{
      //   return res;
      // } ),
      catchError((err)=> this.handleError(err))
    );
  }
  selectSupplier(){
    return this.http.post(`${environment.API_URL}/proveedores`,JSON.stringify({
      Fini: "",
      Ffin: "" 
     }))
    .pipe(
      // map((res:InvoicesResponce)=>{
      //   return res;
      // } ),
      catchError((err)=> this.handleError(err))
    );
  }
  updateSupplier(id: number){
    return this.http.post(`${environment.API_URL}/bajaSupplier`,JSON.stringify({
      id: id
     }))
    .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  insertQ(
    QUno:string
    ,QDos: string
    ,QTres: string
    ,QCuatro: string
    ,QCinco: string
    ,QSeis: string
    ,QSiete: string
    ,userRfc: string
    ,userIds: string
    ,Planta: string
    ){
    return this.http.post(`${environment.API_URL}/insertquestion`,JSON.stringify({
      QUno: QUno,
      QDos: QDos,
      QTres: QTres,
      QCuatro: QCuatro,
      QCinco: QCinco,
      QSeis: QSeis,
      QSiete: QSiete,
      userRfc: userRfc,
      userIds: userIds,
      Planta: Planta
    }))
    .pipe(map((res:InvoicesResponce)=>{
        return res;
      } ),
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadContrato(rfcProveCntr: String,fechaIniCntr: String,fechaFinCntr:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesCntr`,JSON.stringify({
       rfcProveCntr: rfcProveCntr,
       fechaIniCntr: fechaIniCntr,
       fechaFinCntr: fechaFinCntr
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadImss(rfcProveImss: String,fechaIniImss: String,fechaFinImss:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesImss`,JSON.stringify({
      rfcProveImss: rfcProveImss,
      fechaIniImss: fechaIniImss,
      fechaFinImss: fechaFinImss
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadInfo(rfcProveInfo: String,fechaIniInfo: String,fechaFinInfo:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesInfo`,JSON.stringify({
      rfcProveInfo: rfcProveInfo,
      fechaIniInfo: fechaIniInfo,
      fechaFinInfo: fechaFinInfo
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadNomi(rfcProveNomi: String,fechaIniNomi: String,fechaFinNomi:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesNomi`,JSON.stringify({
      rfcProveNomi: rfcProveNomi,
      fechaIniNomi: fechaIniNomi,
      fechaFinNomi: fechaFinNomi
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadStps(rfcProveStps: String,fechaIniStps: String,fechaFinStps:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesStps`,JSON.stringify({
      rfcProveStps: rfcProveStps,
      fechaIniStps: fechaIniStps,
      fechaFinStps: fechaFinStps
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  donwloadImpu(rfcProveImpu: String,fechaIniImpu: String,fechaFinImpu:String){
   
    return this.http
     .post(`${environment.API_URL}/downloadFilesImpu`,JSON.stringify({
      rfcProveImpu: rfcProveImpu,
      fechaIniImpu: fechaIniImpu,
      fechaFinImpu: fechaFinImpu
    }),{responseType:'arraybuffer'})
     .pipe(
      catchError((err)=> this.handleError(err))
    );
  }
  cmbPlanta(id: number){
    return this.http
       .post<InvoicesResponce>(`${environment.API_URL}/comboPlanta`,JSON.stringify({
        id: id      
      }))
       .pipe(map((res:InvoicesResponce)=>{
          return res;
        } ),
        catchError((err)=> this.handleError(err))
      );
  }
  taxDoctoByPlanta(planta: string){
    return this.http
       .post(`${environment.API_URL}/taxValidarByPlanta`,JSON.stringify({
        planta: planta}))
       .pipe(
        catchError((err)=> this.handleError(err))
      );
  }
  cmbPlantas(){
    return this.http
       .post<InvoicesResponce>(`${environment.API_URL}/comboPlantas`,JSON.stringify({
          
      }))
       .pipe(map((res:InvoicesResponce)=>{
          return res;
        } ),
        catchError((err)=> this.handleError(err))
      );
  }

}
