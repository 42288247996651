import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
  // styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  tipo:   string
  nombre: string
  imageSrc = 'supplier-front/assets/images/ppse.png'  
  imageAlt = 'PPSE'
  constructor(public authSvc: AuthService) { }

  ngOnInit(): void {
    this.tipo   = localStorage.getItem('tipo');
    this.nombre = localStorage.getItem('name');
  }
  onLogout(): void{
    this.authSvc.logout();
  }

}
