import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 import { AuthService } from '../../services/auth.service';
 import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
 import {environment} from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import {NgbModal, ModalDismissReasons,NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {
   aFormGroup: FormGroup;
   siteKey:string;
// private suscription:Subscription []=[];
private suscription:Subscription;
  submitted = false;
  myFormRe: FormGroup;
  name: string;
  email: string;
  password: string;
  rfc: string;
  tipo: string;
  public msg:      Array<any> =[]
  formLogin = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
   });

  constructor(
    private formBuilder: FormBuilder,
    private authSvc: AuthService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router
  ) { 
        // this.siteKey ="6Lc93PAbAAAAALvfP0mkv_RRnulZVWI-H2ZYBsrf";
         this.siteKey ="6Lc9-Q0cAAAAACKkyz9lQJSI2dzdzIgYxvfmkT_f";
  
  }


  ngOnInit(): void {
    this.aFormGroup = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
      // recaptcha: ['', Validators.required]
    });
    this.myFormRe = this.formBuilder.group({
          'name':new FormControl('',{validators:[Validators.required]}),
          'rfc':new FormControl('',{validators:[Validators.required]}),
          'email':new FormControl('',{validators:[Validators.required,Validators.email]}),
          
          'password':new FormControl('',{validators:[Validators.required]})
          // 'tipo':new FormControl('',{validators:[Validators.required]})
        })
  }
 
  ngOnDestroy(): void{
    // this.suscription.forEach((sub)=>sub.unsubscribe);
    this.suscription.unsubscribe();
  }

  get f() { return this.aFormGroup.controls; }

  onLogin(){
    this.submitted = true;
    if (this.aFormGroup.invalid) {
      alert("Verifica el captcha")
      return;
    }
    const formValue= this.aFormGroup.value;
    // console.log(formValue);
    // this.suscription.add(
      let postData = {
        email: this.email,
        password: this.password,
   
      }

   this.suscription = this.authSvc.login(postData).subscribe(res=>{
        if (res){
          const tipo  = localStorage.getItem('tipo');
          const acept = localStorage.getItem('acepta');
          // localStorage.setItem('user',String(this.email));
          if (tipo=='PR'){
              if (acept =='NO'){
                this.router.navigate(["aviso"]);
              }else{
                this.router.navigate(["documents"]);
              }
          
          }
          if (tipo=='AP'){
            this.router.navigate(["apcaptur"]);
          }
          if (tipo=='TA'){
            this.router.navigate(["taxcreen"]);
          }
          if (tipo=='RH'){
            this.router.navigate(["rhscreen"]);
          }
         
        }
      })
    // )
  }
  openXl(content2) {
    this.modalService.open(content2, { size: 'sm' });  
  }
  Registrar(content2:any){
    this.openXl(content2)
  }
 registration(){
   var msgs;
  if(this.myFormRe.invalid){
    alert("Todos los campos son requeridos.");
    return 0;
    }
    let postDatos = {
        name: this.name,
        email: this.email,
        password: this.password,
        rfc: this.rfc,
        tipo: "PR"
    }
  // const express =require("express")
  // const app =express()

  this.http.post<any>(`${environment.API_URL}/register`, postDatos).subscribe((res:any)=> {  
    console.log(res); 
    this.msg=res;
    // this.msg.forEach(function(arrayItem){
    //   msgs=arrayItem.warning

    // }) 
    for (const mg in res){
      alert(res[mg]);
    }
    this.myFormRe.reset();  
     
    })
 }
}
export class Ilogin{
  email:   String
  password: String
}