<!-- <div  id="staticBackdropp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content ">
              <div class="modal-header ">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                 Sonoco
                </h1>
                
               
              </div>
              <p class="text-center">
              
                
              </p>
              <div class="modal-body text-center">
                <div  class="btn btn-light" >
                    
                    <mat-spinner [diameter]="50"
                    [color]="color"
                    [mode]="mode"
                    [value]="value"
                    ></mat-spinner>
               
                </div>
              </div>
            
        </div>
    </div>
</div> -->

<h3 mat-dialog-title>Sonoco</h3>
<div mat-dialog-content>
  
  <ul>
    <li>
      <span *ngIf="data.animal === 'panda'">&#10003;</span> {{this.data.Msg}}
    </li>
    
  </ul>
</div>
