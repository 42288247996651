import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { SuppliersService } from '../../services/suppliers.service';
import { FormGroup, FormBuilder,FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.scss']
})
export class AvisoComponent implements OnInit {
  public rfc: string;
  public user: string;
  public nombre: string;
  public cmbRazon:      Array<any> =[]
  public planta: string;
  public razonSocial: string;
  name = 'Set iframe source';
  url: string = "";
  urlSafe: SafeResourceUrl;
  myFormAp: FormGroup;
  constructor(
    public sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private scvSuppl: SuppliersService
    ) { }

  ngOnInit(): void {
    this.cmbRazonSoc();
    this.myFormAp = this.formBuilder.group({
      'razonSocial':new FormControl('',{validators:[Validators.required]})
     
    })
  
  }
  cmbRazonSoc(){
    
    this.user = localStorage.getItem('user')
    this.rfc =localStorage.getItem('rfc')
     this.scvSuppl.selectRazonSoc(this.user,this.rfc).subscribe((resp:any)=> {
       this.cmbRazon=resp;
      //  alert(resp);
       })
   }
   onchangeRazon(x){
      // console.log(x.target.options[x.target.options.selectedIndex].text);
      var txtDes= x.target.options[x.target.options.selectedIndex].text;
     this.razonSocial=txtDes;
      // console.log(x.target.options[x.target.options.selectedIndex].value);
      this.planta= x.target.options[x.target.options.selectedIndex].value;
      // alert(x.options[x.selectedIndex].text);
      if(txtDes=="Manufacturas Sonoco SA de CV"){
        this.url="supplier-front/assets/Avisos/2021_ManufacturasSonoco_API_Proveedores_Especializados.pdf";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
      if(txtDes=="Conitex Sonoco México S de RL de CV"){
        this.url="supplier-front/assets/Avisos/2021_Conitex_API_Proveedores_Especializados.pdf";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
      if(txtDes=="Penpack S de RL de CV"){
        this.url="supplier-front/assets/Avisos/2021_Penpack_API_Proveedores_Especializados.pdf";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
      if(txtDes=="Tegrant de México SA de CV"){
        this.url="supplier-front/assets/Avisos/2021_Tegrant_API_Proveedores_Especializados.pdf";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
      if(txtDes=="Sonoco SA de CV"){
        this.url="supplier-front/assets/Avisos/2021_Sonoco_API_Proveedores_Especializados.pdf";
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      }
   }
   submitAviso(){
    if(this.myFormAp.invalid){
      alert("Seleccione la empresa.");
      return 0;
      }
      // const{razonSocial}=this.myFormAp.value;
      this.user = localStorage.getItem('user')
      this.rfc =localStorage.getItem('rfc')
      this.nombre =localStorage.getItem('name')
      this.scvSuppl.insertAviso(this.rfc,this.user,this.nombre,this.razonSocial,this.planta).subscribe((resp:any)=> {
    
       alert("Proceso completado.");
       })

   }
}
